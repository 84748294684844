import { Box, Flex, FormControl, FormLabel, Switch, Text } from '@chakra-ui/react';
import { useColorModeValue } from '@chakra-ui/system';
import { useTranslation } from 'react-i18next';
import { Job, JobMode } from 'api';
import { DetailInputField } from 'libs/ui/atoms/src/lib/input-field/InputField';
import { FieldState } from '../widgets/JobDetailsWidget/useJobForm';
import { useMatch, useNavigate } from 'react-router-dom';
import { JobDetailsExtraData } from '../widgets/JobDetailsWidget/JobDetailsExtraData';
import { useAppDispatch, useTypedSelector } from 'store';
import { jobWizardChecked } from 'store/jobWizard';
import { JobModeSelector } from './job-mode-selector';

export interface JobMainInfoProps {
  isReadOnly?: boolean;
  job: Job;
  hideExtraData?: boolean;
  jobName: FieldState;
  onNameUpdate: (value: string) => void;
  /**
   * @default false
   */
  showJobModeWidget?: boolean;
  isJobModeInColumn?: boolean;
  isWizardMode?: boolean;
  onJobModeChange?: (jobMode: JobMode) => void;
}

export function JobMainInfoWidget({
  isReadOnly,
  job,
  jobName,
  hideExtraData = false,
  showJobModeWidget = false,
  isJobModeInColumn,
  isWizardMode,
  onNameUpdate,
  onJobModeChange,
}: JobMainInfoProps) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const isJobWizardChecked: boolean = useTypedSelector(state => state.wizard.jobWizard.isChecked);

  const isContractsParties = useMatch('/jobs/:jobId/contracts-and-parties');

  const textColor = useColorModeValue('gray.600', 'white');
  const grayTextColor = useColorModeValue('gray.500', 'white');
  const brandStars = useColorModeValue('brand.500', 'white');
  const widgetBackgroundColor = useColorModeValue('white', 'navy.800');

  const handleWizardToggle = () => {
    dispatch(jobWizardChecked({ wizardKey: 'jobWizard', isChecked: !isJobWizardChecked }));
    navigate(`/jobs/${job.id}`);
  };

  const wizardSlider = (
    <Flex direction="row" gap={'10px'}>
      <Text>{t('pages.wizard.step1.docWizard')}</Text>
      <Switch isChecked={isJobWizardChecked} onChange={handleWizardToggle} />
    </Flex>
  );

  const isJobPage = useMatch('/jobs/:jobId');

  return (
    <Flex flexDir={'column'} gap={isWizardMode ? '20px' : '0'}>
      <Flex
        direction="row"
        align={hideExtraData ? '' : 'flex-end'}
        gap={isJobWizardChecked && isJobPage ? '' : '20px'}
        p={isJobWizardChecked && isJobPage ? '' : '20px'}
        bg={widgetBackgroundColor}
        borderRadius={'20px'}
      >
        <Flex direction="column" flex="1" gap="20px">
          {isJobWizardChecked && isJobPage && (
            <Flex direction={'column'}>
              <Flex direction={'row'} justify={'space-between'}>
                <Text color={textColor} fontWeight="700" fontSize={'18px'} width="fit-content">
                  {t('pages.wizard.step1.jobDetails')}
                </Text>
                <Flex float={'right'}>{wizardSlider}</Flex>
              </Flex>

              <Flex width="fit-content">
                <Text color={grayTextColor} fontWeight="400" fontSize={'12px'}>
                  {t('pages.wizard.step1.step1Description')}
                </Text>
              </Flex>
            </Flex>
          )}
          <FormControl
            gap="24px"
            display="flex"
            justifyContent="space-between"
            position="static"
            flexDirection={{ base: 'column', md: 'row' }}
          >
            <Box flex={1}>
              <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                {t('pages.details.name')}
                <Text color={brandStars}>*</Text>
              </FormLabel>
              <DetailInputField
                isReadOnly={isReadOnly}
                value={jobName.value}
                onChange={event => onNameUpdate(event.target.value)}
                isInvalid={!!jobName.error}
                placeholder={t('pages.details.name')}
              />
              {jobName.error && <Text color="red.400">{jobName.error}</Text>}
            </Box>
          </FormControl>
        </Flex>
        {!isContractsParties && (
          <Flex gap="10px" direction="column" alignItems="flex-end" alignSelf="flex-start">
            {!isJobWizardChecked && (
              <>
                <Flex float={'right'}>{wizardSlider}</Flex>
                {!hideExtraData && (
                  <Box order={{ base: 0, md: 0 }}>
                    <JobDetailsExtraData job={job} />
                  </Box>
                )}
              </>
            )}
          </Flex>
        )}
      </Flex>

      {showJobModeWidget && onJobModeChange && (
        <JobModeSelector
          jobMode={job.mode}
          onChange={onJobModeChange}
          isJobModeInColumn={isJobModeInColumn}
          p={isWizardMode ? '0px' : '0 20px 0 20px'}
        />
      )}
    </Flex>
  );
}
