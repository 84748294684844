import { FormControl, FormLabel, Input, InputGroup, InputLeftElement, Text, useColorModeValue } from '@chakra-ui/react';
import { ChangeEvent, HTMLInputTypeAttribute, ReactNode, useMemo } from 'react';
import { formatDateToYYYYMMDD } from '../../../../../../utils/date';
import { NationalityFormInput } from './natinality-form-iniput';
import { PhoneFormInput } from './phone-form-iniput';
import { PostalCodeInputField } from './postal-code-input-field';
import { PriceInputField } from './price-input-field';

import { IBANInputField } from './iban-input-field';
import { NIFInputField } from './nif-input-field';

export interface FormInputFieldProps {
  value?: string | number | Date | null;
  label?: string;
  type?: HTMLInputTypeAttribute;
  onChange?: (value: string) => void;
  onBlur?: (value: string) => void;

  isReadonly?: boolean;
  isRequired?: boolean;
  isInvalid?: boolean;
  hideLabel?: boolean;
  errorMessage?: string | null;

  leftChildren?: ReactNode;

  maxLength?: number;
}

function FormInputField({
  value,
  label,
  type,
  onChange,
  onBlur,
  isReadonly,
  isInvalid,
  hideLabel,
  errorMessage,
  isRequired,
  leftChildren,
  maxLength,
}: FormInputFieldProps) {
  const textColor = useColorModeValue('navy.750', 'white');
  const errorBorderColor = useColorModeValue('red.500', 'red.500');

  const valueFormatted = useMemo(() => {
    if (value instanceof Date) {
      return formatDateToYYYYMMDD(new Date(value));
    }
    return value;
  }, [value]);

  const handleBlur = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    if (isReadonly) return;
    if (onBlur) onBlur(inputValue);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (maxLength && type === 'number') {
      e.target.value = e.target.value.slice(0, maxLength);
    }

    const inputValue = e.target.value;
    onChange?.(inputValue);
  };

  return (
    <FormControl maxW={'100%'} overflow="hidden" p="1px">
      {!hideLabel && label && (
        <FormLabel mb="4px" maxW={'100%'} overflow="hidden" display={'flex'} title={label}>
          <Text className="no-text-wrap" as="span" textOverflow={'ellipsis'} overflow="hidden">
            {label}
          </Text>
          {isRequired && (
            <Text as={'span'} color={'red'}>
              {' *'}
            </Text>
          )}
        </FormLabel>
      )}

      {leftChildren ? (
        <InputGroup>
          <InputLeftElement pointerEvents="none" height={'100%'}>
            {leftChildren}
          </InputLeftElement>
          <Input
            borderColor={isInvalid ? errorBorderColor : undefined}
            height={'50px'}
            borderRadius={'10px'}
            color={textColor}
            type={type}
            placeholder={label}
            readOnly={isReadonly}
            value={valueFormatted ?? ''}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={!!isInvalid}
            textOverflow={'ellipsis'}
          />
        </InputGroup>
      ) : (
        <InputGroup>
          <Input
            height={'50px'}
            borderColor={isInvalid ? errorBorderColor : undefined}
            borderRadius={'10px'}
            color={textColor}
            type={type}
            readOnly={isReadonly}
            placeholder={label}
            value={valueFormatted ?? ''}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={!!isInvalid}
            textOverflow={'ellipsis'}
          />
        </InputGroup>
      )}
      {isInvalid && errorMessage && (
        <Text color={errorBorderColor} fontSize="sm">
          {errorMessage}
        </Text>
      )}
    </FormControl>
  );
}

FormInputField.Nationality = NationalityFormInput;
FormInputField.Phone = PhoneFormInput;
FormInputField.PostalCode = PostalCodeInputField;
FormInputField.Price = PriceInputField;
FormInputField.IBAN = IBANInputField;
FormInputField.NIF = NIFInputField;

export { FormInputField };
