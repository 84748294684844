import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppButton, AppButtonColorScheme, AppButtonType, SelectionMenuOption } from 'libs/ui/atoms/src';
import { useAppDispatch, useTypedSelector } from 'store';
import { setOrganizationId } from 'store/auth';
import { Box, Flex, useColorModeValue } from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';

export const OrganizationSelector = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const personalMode = useTypedSelector(state => state.auth.user?.isPersonalEnabled);
  const organizations = useTypedSelector(state => state.auth.user?.organizations);
  const organizationId = useTypedSelector(state => state.auth.organizationId);

  const options = useMemo<SelectionMenuOption[]>(
    () => [
      ...(personalMode !== false ? [{ title: t('personal'), value: null }] : []),
      ...(organizations?.map(org => ({
        title: org.name,
        value: org.id,
      })) || []),
    ],
    [organizations, organizationId],
  );

  const changeOrganization = (organizationId: string) => {
    dispatch(setOrganizationId({ organizationId }));
    navigate('/home');
  };

  const [isOpen, setIsOpen] = useState(false);

  const currentOption = options.find(o => o.value === organizationId);

  const bg = useColorModeValue('secondaryGray.300', 'navy.700');

  return (
    <Box position="relative" width="100%">
      <AppButton
        buttonType={AppButtonType.PRIMARY}
        colorSchemes={[AppButtonColorScheme.DISABLED]}
        isWidePadding={true}
        title={currentOption?.title}
        onClick={() => setIsOpen(!isOpen)}
        rightIcon={<ChevronDownIcon />}
        isDisabled={options.length === 1}
        width={'100%'}
      />
      {isOpen && (
        <Flex
          direction="column"
          align="center"
          position="absolute"
          width="100%"
          bg={bg}
          borderRadius="10px"
          boxShadow="md"
          zIndex="10"
          p="4px"
          gap="4px"
        >
          {options.map(option => (
            <AppButton
              key={option.value}
              buttonType={AppButtonType.PRIMARY}
              isDisabled={option.value === organizationId}
              colorSchemes={[
                option.value === organizationId ? AppButtonColorScheme.DISABLED : AppButtonColorScheme.SECONDARY,
              ]}
              title={option.title}
              onClick={() => {
                changeOrganization(option.value);
                setIsOpen(false);
              }}
              isWidePadding={true}
              width={'100%'}
            />
          ))}
        </Flex>
      )}
    </Box>
  );
};
