import { Flex, Icon, Text, useColorModeValue } from '@chakra-ui/react';
import { CheckCircleOutlinedIcon } from '../../../../../../components/icons/Icons';
import { CircleOutlinedIcon } from '../icons/icons';

export interface VCSelectionCardProps<TValue> {
  isChecked: boolean;
  value: TValue;
  title: string;
  subtitle: string;
  onClick: (value: TValue) => void;
}

export const VCSelectionCard = <TValue,>(props: VCSelectionCardProps<TValue>) => {
  const { isChecked, title, subtitle, value, onClick } = props;

  const textColor = useColorModeValue('blue.800', 'white');
  const textColorActive = useColorModeValue('blue.500', 'white');
  const subtitleColor = useColorModeValue('_gray.500', '_gray.400');
  const bg = useColorModeValue('_gray.50', 'navy.700');
  const bgActive = useColorModeValue('blue.50', 'navy.750');

  return (
    <Flex
      onClick={() => onClick(value)}
      cursor="pointer"
      justifyContent={'flex-start'}
      height={'100%'}
      flex="1"
      width={'100%'}
      flexDir={'column'}
      gap={'2px'}
      borderRadius={'15px'}
      p={'8px 8px 8px 16px'}
      borderColor={isChecked ? textColorActive : 'transparent'}
      borderWidth={'1px'}
      _hover={{ borderColor: isChecked ? textColorActive : subtitleColor }}
      bg={isChecked ? bgActive : bg}
    >
      <Flex justifyContent={'space-between'} alignItems={'flex-start'} gap={'12px'}>
        <Text fontSize={'16px'} fontWeight="700" color={isChecked ? textColorActive : textColor}>
          {title}
        </Text>
        {isChecked ? (
          <Icon as={CheckCircleOutlinedIcon} color={textColorActive} boxSize="20px" mt="2px" />
        ) : (
          <CircleOutlinedIcon boxSize="20px" color={textColor} mt="2px" />
        )}
      </Flex>
      <Text fontSize={'14px'} fontWeight="400" color={subtitleColor}>
        {subtitle}
      </Text>
    </Flex>
  );
};
