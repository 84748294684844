import { useToast } from '@chakra-ui/react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export const useAppToast = () => {
  const { t } = useTranslation();
  const toast = useToast();

  const showSuccess = useCallback(
    (title: string, duration = 1500) => {
      toast({ title, status: 'success', duration, isClosable: true });
    },
    [toast],
  );

  const showError = useCallback(
    (description?: string, duration = 5000) => {
      toast({ title: t('error'), description, status: 'error', duration, isClosable: true });
    },
    [toast, t],
  );

  const showCatchedError = useCallback(
    (error: unknown, title?: string, duration = 5000) => {
      const errorMsg = (error as Error)?.message ?? t('error');
      toast({ title: title ?? t('error'), description: errorMsg, status: 'error', duration, isClosable: true });
    },
    [toast, t],
  );

  const showErrorCustom = useCallback(
    (title: string, description: string, duration = 5000) => {
      toast({
        title,
        description,
        status: 'error',
        duration,
        isClosable: true,
      });
    },
    [toast],
  );

  const showApiError = useCallback(
    (apiError: unknown, title?: string, duration = 5000) => {
      showErrorCustom(title ?? t('error'), t((apiError as { data: Error })?.data?.message), duration);
    },
    [showErrorCustom, t],
  );

  const showValidationError = useCallback(
    (description?: string, duration = 5000) => {
      toast({
        title: t('validationError'),
        description,
        status: 'error',
        duration,
        isClosable: true,
      });
    },
    [toast, t],
  );

  const showWarning = useCallback(
    (title: string, duration = 1500) => {
      toast({ title, status: 'warning', duration, isClosable: true });
    },
    [toast],
  );

  const showInfo = useCallback(
    (title: string, duration = 1500) => {
      toast({ title, status: 'info', duration, isClosable: true });
    },
    [toast],
  );

  return {
    showInfo,
    showSuccess,
    showError,
    showApiError,
    showValidationError,
    showErrorCustom,
    showWarning,
    showCatchedError,
  };
};
