import { useCallback, useMemo, useReducer } from 'react';
import { JobMetadataR01X04BankTransferItem } from '../../api';
import { isIbanValid } from '../../utils';

type SetInitialStateAction = { type: 'setInitialState'; value: JobMetadataR01X04BankTransferItem[] };
type SetIbanRemitter = { type: 'setIbanRemitter'; value: { index: number; value: string } };
type SetIbanBeneficiary = { type: 'setIbanBeneficiary'; value: { index: number; value: string } };
type SetAmount = { type: 'setAmount'; value: { index: number; value: number } };
type AddItem = { type: 'addItem' };
type RemoveItem = { type: 'removeItem'; index: number };

type RightOfFirstRefusalFormAction =
  | SetInitialStateAction
  | SetIbanRemitter
  | SetIbanBeneficiary
  | SetAmount
  | AddItem
  | RemoveItem;
export type BankTransfersFormState = JobMetadataR01X04BankTransferItem[];
const defaultItem = {
  'transfer:iban:remitter': '',
  'transfer:iban:beneficiary': '',
  amount: 0,
} as JobMetadataR01X04BankTransferItem;

export const useBankTransfersForm = () => {
  const [form, dispatch] = useReducer((state: BankTransfersFormState, action: RightOfFirstRefusalFormAction) => {
    switch (action.type) {
      case 'setInitialState':
        return [...action.value];

      case 'setIbanRemitter': {
        return state.map((item, index) =>
          index === action.value.index ? { ...item, 'transfer:iban:remitter': action.value.value } : item,
        );
      }

      case 'setIbanBeneficiary': {
        return state.map((item, index) =>
          index === action.value.index ? { ...item, 'transfer:iban:beneficiary': action.value.value } : item,
        );
      }

      case 'setAmount': {
        return state.map((item, index) =>
          index === action.value.index ? { ...item, amount: action.value.value } : item,
        );
      }

      case 'addItem': {
        return [...state, { ...defaultItem }];
      }

      case 'removeItem': {
        return state.filter((_, index) => index !== action.index);
      }
      default:
        return state;
    }
  }, []);

  const totalAmount = useMemo(() => form.reduce((acc, item) => acc + (item.amount ?? 0), 0), [form]);

  const validate = useCallback(() => {
    const errorMsgs = [];

    if (form.some(item => !item['transfer:iban:remitter'] || !isIbanValid(item['transfer:iban:remitter']))) {
      errorMsgs.push('impic.form.bankTransfers.remitterIbanIsRequired');
    }
    if (form.some(item => !item['transfer:iban:beneficiary'] || !isIbanValid(item['transfer:iban:beneficiary']))) {
      errorMsgs.push('impic.form.bankTransfers.beneficiaryIbanIsRequired');
    }
    if (form.some(item => !item.amount)) {
      errorMsgs.push('impic.form.bankTransfers.amountIsRequired');
    }

    return errorMsgs;
  }, [form]);

  return { form, dispatch, validate, totalAmount };
};
