import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { AppButton, AppButtonColorScheme, AppButtonType, Download } from 'libs/ui/atoms/src';
import { useTypedSelector } from 'store';
import { useMatch } from 'react-router-dom';

export const DownloadAllFilesButton = () => {
  const { t } = useTranslation();

  const selectedJob = useTypedSelector(state => state.job.selectedJob);

  const downloadAll = useCallback(async () => {
    if (!selectedJob || !selectedJob.publicId) {
      return;
    }

    const response = await fetch(selectedJob?.filesArchiveUrl, {
      method: 'GET',
    });

    const blobUrl = URL.createObjectURL(await response.blob());
    const link = document.createElement('a');
    link.href = blobUrl;
    link.setAttribute('download', `${selectedJob.name ?? 'vericasareport'}.zip`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(blobUrl);
  }, [selectedJob]);

  const isResultPage = useMatch('/job-result/:jobId');
  const isPublicPage = useMatch('/r/:jobId/:language?');

  return selectedJob?.filesArchiveUrl && (!!isResultPage || !!isPublicPage) ? (
    <AppButton
      buttonType={AppButtonType.SECONDARY}
      onClick={downloadAll}
      children={<Download w="24px" h="24px" />}
      tooltip={t('pages.details.downloadAll')}
      colorSchemes={[AppButtonColorScheme.LEMON]}
    />
  ) : (
    <></>
  );
};
