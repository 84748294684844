import { ReactNode, useCallback, useEffect, useState } from 'react';
import {
  FormControl,
  FormLabel,
  InputGroup,
  InputLeftElement,
  Text,
  useColorModeValue,
  Input,
  StyleProps,
} from '@chakra-ui/react';
import { NumericFormat, NumberFormatValues } from 'react-number-format';

export interface PriceInputFieldProps extends StyleProps {
  value?: number;
  label?: string;
  prefix?: string;
  decimalScale?: number;

  onChange?: (value: number | string) => void;
  onBlur?: (value: number | string) => void;

  isReadonly?: boolean;
  isRequired?: boolean;
  isInvalid?: boolean;
  hideLabel?: boolean;
  hideBorders?: boolean;

  leftChildren?: ReactNode;

  maxLength?: number;

  thousandSeparator?: string;
  decimalSeparator?: string;
  useFormattedValue?: boolean;
}

export function PriceInputField({
  value,
  label,
  prefix = '€ ',
  decimalScale = 2,
  onChange,
  onBlur,
  isReadonly,
  isInvalid,
  isRequired,
  hideLabel,
  hideBorders,
  leftChildren,
  maxLength,
  thousandSeparator = '.',
  decimalSeparator = ',',
  useFormattedValue = false,
  ...styleProps
}: PriceInputFieldProps) {
  const textColor = useColorModeValue('navy.750', 'white');
  const errorBorderColor = useColorModeValue('red.500', 'red.500');

  const [displayValue, setDisplayValue] = useState<string>(value?.toString() || '');
  const [rawValue, setRawValue] = useState<number>(value || 0);

  const reformatValueWithPrefix = useCallback(
    (value: string): string => {
      const cleanValue = value.replace(prefix, '').trim();
      const cleanPrefix = prefix.trim();
      return `${cleanValue} ${cleanPrefix}`;
    },
    [prefix],
  );

  const handleValueChange = useCallback(
    (values: NumberFormatValues) => {
      const { floatValue, formattedValue } = values;

      setDisplayValue(formattedValue);
      setRawValue(floatValue || 0);

      onChange?.(useFormattedValue ? reformatValueWithPrefix(formattedValue) : floatValue || 0);
    },
    [onChange, reformatValueWithPrefix, useFormattedValue],
  );

  const isAllowed = useCallback(
    (values: NumberFormatValues) => {
      const { floatValue } = values;
      return !maxLength || !floatValue || floatValue.toString().split(decimalSeparator)[0].length <= maxLength;
    },
    [decimalSeparator, maxLength],
  );

  const handleBlur = useCallback(() => {
    if (isReadonly || !onBlur) return;
    const result = useFormattedValue ? reformatValueWithPrefix(displayValue) : rawValue;
    onBlur(result);
  }, [displayValue, isReadonly, onBlur, rawValue, reformatValueWithPrefix, useFormattedValue]);

  useEffect(() => {
    setRawValue(value || 0);
    setDisplayValue(value?.toString() || '');
  }, [reformatValueWithPrefix, value]);

  return (
    <FormControl maxW="100%" overflow="hidden" isInvalid={isInvalid} p="1px">
      {!hideLabel && label && (
        <FormLabel mb="4px" display="flex" title={label}>
          <Text className="no-text-wrap" as="span" textOverflow="ellipsis" overflow="hidden">
            {label}
          </Text>
          {isRequired && (
            <Text as="span" color="red">
              {' *'}
            </Text>
          )}
        </FormLabel>
      )}

      <InputGroup>
        {leftChildren && (
          <InputLeftElement pointerEvents="none" height={'100%'}>
            {leftChildren}
          </InputLeftElement>
        )}

        <NumericFormat
          customInput={Input}
          prefix={prefix}
          thousandSeparator={thousandSeparator}
          decimalSeparator={decimalSeparator}
          decimalScale={decimalScale}
          fixedDecimalScale
          allowNegative={false}
          height="50px"
          _hover={{ borderColor: hideBorders ? 'transparent' : isInvalid ? errorBorderColor : undefined }}
          borderColor={hideBorders ? 'transparent' : isInvalid ? errorBorderColor : undefined}
          borderRadius="10px"
          color={textColor}
          placeholder={label}
          disabled={isReadonly}
          value={displayValue}
          onValueChange={handleValueChange}
          onBlur={handleBlur}
          isAllowed={isAllowed}
          textOverflow={'ellipsis'}
          style={{ ...styleProps }}
        />
      </InputGroup>
    </FormControl>
  );
}
