import { Flex, Grid, Text, useColorModeValue } from '@chakra-ui/react';
import { TableInlineEditCell, TableInlineEditCellProps } from './TableInlineEditCell';

export interface TableInlineEditColumn {
  columnKey: string;
  title: string;
  isRequired?: boolean;

  minWidthPercent?: number;
}

export interface TableInlineEditRow<TValue = string> {
  fields: TableInlineEditCellProps<TValue>[];
  rightActions?: React.ReactNode;
}

export interface TableInlineEditProps<TValue = string> {
  columns: TableInlineEditColumn[];
  rows: TableInlineEditRow<TValue>[];
}

export const TableInlineEdit = <TValue extends string | number>({ columns, rows }: TableInlineEditProps<TValue>) => {
  const columnTitleColor = useColorModeValue('_gray.500', '_gray.400');

  return (
    <Grid
      className="table-inline-edit"
      templateColumns={columns.map(i => (i.minWidthPercent ?? 50) + '%').join(' ')}
      gap="0"
    >
      {columns.map((column, colIdx) => (
        <Text
          key={column.columnKey + colIdx}
          className={column.columnKey + colIdx}
          fontSize="14px"
          fontWeight="500"
          color={columnTitleColor}
          mb="8px"
        >
          {column.title}
        </Text>
      ))}

      {rows.map((row, rowIdx) => (
        <>
          {row.fields.map((cell, cellIdx) => (
            <TableInlineEditCell<TValue> key={rowIdx.toString() + cellIdx.toString()} {...cell} />
          ))}

          {row.rightActions && (
            <Flex key={'rightActions-' + rowIdx} alignItems="center" justifyContent={'center'} p="0" m="0">
              {row.rightActions}
            </Flex>
          )}
        </>
      ))}
    </Grid>
  );
};
