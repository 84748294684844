import { useCallback, useMemo, useReducer } from 'react';
import { JobMetadataR01X04ChequeItem } from '../../api';

type SetInitialStateAction = { type: 'setInitialState'; value: JobMetadataR01X04ChequeItem[] };
type SetChequeNumber = { type: 'setChequeNumber'; value: { index: number; value: number } };
type SetChequeDrawee = { type: 'setChequeDrawee'; value: { index: number; value: string } };
type SetAmount = { type: 'setAmount'; value: { index: number; value: number } };
type AddItem = { type: 'addItem' };
type RemoveItem = { type: 'removeItem'; index: number };

type RightOfFirstRefusalFormAction =
  | SetInitialStateAction
  | SetChequeNumber
  | SetChequeDrawee
  | SetAmount
  | AddItem
  | RemoveItem;
export type ChequesFormState = JobMetadataR01X04ChequeItem[];
const defaultItem: JobMetadataR01X04ChequeItem = {
  'transfer:cheque:number': undefined,
  'transfer:cheque:drawee': '',
  amount: 0,
};

export const useChequesForm = () => {
  const [form, dispatch] = useReducer((state: ChequesFormState, action: RightOfFirstRefusalFormAction) => {
    switch (action.type) {
      case 'setInitialState':
        return [...action.value];

      case 'setChequeNumber': {
        return state.map((item, index) =>
          index === action.value.index ? { ...item, 'transfer:cheque:number': action.value.value } : item,
        );
      }

      case 'setChequeDrawee': {
        return state.map((item, index) =>
          index === action.value.index ? { ...item, 'transfer:cheque:drawee': action.value.value } : item,
        );
      }

      case 'setAmount': {
        return state.map((item, index) =>
          index === action.value.index ? { ...item, amount: action.value.value } : item,
        );
      }

      case 'addItem': {
        return [...state, { ...defaultItem }];
      }

      case 'removeItem': {
        return state.filter((_, index) => index !== action.index);
      }
      default:
        return state;
    }
  }, []);

  const totalAmount = useMemo(() => form.reduce((acc, item) => acc + (item.amount ?? 0), 0), [form]);

  const validate = useCallback(() => {
    const errorMsgs = [];

    if (form.some(item => !item['transfer:cheque:drawee'])) {
      errorMsgs.push('impic.form.cheques.draweeIsRequired');
    }
    if (form.some(item => !item['transfer:cheque:number'])) {
      errorMsgs.push('impic.form.cheques.numberIsRequired');
    }
    if (form.some(item => !item.amount)) {
      errorMsgs.push('impic.form.cheques.amountIsRequired');
    }

    return errorMsgs;
  }, [form]);

  return { form, dispatch, validate, totalAmount };
};
