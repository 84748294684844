import { Trans, useTranslation } from 'react-i18next';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  ImpicBankTransferItem,
  ImpicChequeItem,
  ImpicDeclarationData,
  Job,
  JobMetadata,
  JobMetadataId,
  JobMetadataItem,
  JobMetadataR01X03,
  JobMetadataR01X03Item,
  JobMetadataR01X04,
  RelatedParty,
  RelatedPartyRelationType,
  RightOfFirstRefusalRelatedParty,
  useBuildImpicXmlMutation,
} from 'api';
import { ImpicFormState, useImpicModalForm } from './useImpicModalForm';
import { ImpicContractPeriod, ImpicQualidadeDeclaranteNT, ImpicTransactionType } from 'api';
import { FormInputField } from '../../libs/ui/atoms/src/lib/input-field/form-input-field';
import { AppButton, AppButtonColorScheme, AppButtonType } from '../../libs/ui/atoms/src/lib/appButton';
import { VCDatePicker } from 'libs/ui/atoms/src/lib/datePicker/DatePicker';
import { formatDateToYYYYMMDD } from 'utils/date';
import { SelectInputInputField } from 'libs/ui/atoms/src/lib/input-field/selectField';
import { tByContractPeriod, tByQualidadeDeclarante, tByTransactionType } from './utils';
import { useRightOfFirstRefusalRelatedParties } from 'components/rightOfFirstRefusalModal/useRelatedPartiesRefusalForm';
import { Link } from 'react-router-dom';
import { useAppToast } from 'libs/ui/hooks';
import { Plus, RecycleBing, RepairingTool } from '../../libs/ui/atoms/src';
import { useTypedSelector } from 'store';
import { TableInlineEdit } from '../../libs/ui/organisms/table-inline-edit/TableInlineEdit';
import { TableInlineEditColumnType } from '../../libs/ui/organisms/table-inline-edit/TableInlineEditCell';
import { PriceInputField } from '../../libs/ui/atoms/src/lib/input-field/price-input-field';
import { BankTransfersFormState } from './useBankTransfersForm';
import { ChequesFormState } from './useChequesForm';
import { useNumericFormat } from 'react-number-format';

function isJobMetadataR01X03(item: JobMetadataItem): item is JobMetadataR01X03 {
  return item.id === JobMetadataId.R01X03;
}

function extractPropertyInfo(jobMetadata: JobMetadata) {
  const R01X03Elements = jobMetadata?.metadata?.find((item): item is JobMetadataR01X03 => isJobMetadataR01X03(item));

  const R01X03Metadata = R01X03Elements?.metadata;

  return R01X03Metadata;
}

function mapFormToImpicDto(
  form: ImpicFormState,
  bankTransfers: ImpicBankTransferItem[],
  cheques: ImpicChequeItem[],
): ImpicDeclarationData {
  return {
    qualidadeDeclarante: form.qualidadeDeclarante ?? ImpicQualidadeDeclaranteNT.MEDIACAO_IMOBILIARIA,
    contractYear: form.contractYear ?? '',
    contractPeriod: form.contractPeriod ?? ImpicContractPeriod.TRIMESTER_1,
    transactionType: form.transactionType ?? ImpicTransactionType.COMPRA,
    transactionDate: form.transactionDate ?? '',
    propertyValue: form.propertyValue ?? '',

    propertyNumber: form.propertyNumber ?? '1',
    quotaParteImoveis: form.quotaParteImoveis ?? '',
    valorRendaIncluiDespEnc: form.valorRendaIncluiDespEnc ?? '',

    codPostal1: form.codPostal1 ?? '',
    codPostal2: form.codPostal2 ?? '',
    localidade: form.localidade ?? '',

    bankTransfers: bankTransfers,
    cheques: cheques,
  };
}

export interface ImpicModalProps {
  job: Job;
  isLoadingInitJobParties: boolean;
  isMainButton?: boolean;
}

export const IMPICModal = ({ job, isLoadingInitJobParties, isMainButton }: ImpicModalProps) => {
  const { t } = useTranslation();
  const [buildImpicXml] = useBuildImpicXmlMutation();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    form,
    dispatch,
    validate,
    formBankTransfers,
    dispatchBankTransfers,
    totalAmountBankTransfers,
    formCheques,
    dispatchCheques,
    totalAmountCheques,
  } = useImpicModalForm();

  const { showError, showApiError, showSuccess } = useAppToast();

  const { buyers, sellers, heirs, heranças, onNifChange, load } = useRightOfFirstRefusalRelatedParties(job.id);
  const [isHerdeirosInsteadOfHerança] = useState<boolean>(true);

  const [metadata, setMetadata] = useState<JobMetadataR01X03Item>();

  const auth = useTypedSelector(state => state.auth);
  const organizationId = useTypedSelector(state => state.auth.organizationId);
  const impicTransactionQualities = useTypedSelector(
    state => state.auth.organizations?.find(org => org.id === organizationId)?.impicTransactionQualities,
  );
  const stateAuth = useTypedSelector(state => state.auth);

  const { format: formatPrice } = useNumericFormat({ thousandSeparator: ' ', decimalSeparator: ',' });

  useEffect(() => {
    if (isOpen) {
      load();
    }
  }, [isOpen, load]);

  const open = useCallback(async () => {
    try {
      const jobBillingMetadata = job?.jobMetadata?.metadata?.find(
        (i): i is JobMetadataR01X04 => i.id === JobMetadataId.R01X04,
      )?.metadata;

      if (jobBillingMetadata?.bank_payments) {
        dispatchBankTransfers({
          type: 'setInitialState',
          value: jobBillingMetadata?.bank_payments,
        });
      }
      if (jobBillingMetadata?.cheque_payments) {
        dispatchCheques({
          type: 'setInitialState',
          value: jobBillingMetadata?.cheque_payments,
        });
      }
      const propertyInfo = extractPropertyInfo(job.jobMetadata);

      const todaysDate = new Date();

      // Prefill contractYear
      const currentYear = todaysDate.getFullYear().toString();
      dispatch({ type: 'setContractYear', value: currentYear });

      // Prefill contractPeriod
      const currentMonth = todaysDate.getMonth() + 1; // month is zero based
      switch (true) {
        case [1, 2, 3].includes(currentMonth):
          dispatch({ type: 'setContractPeriod', value: ImpicContractPeriod.TRIMESTER_1 });
          break;
        case [4, 5, 6].includes(currentMonth):
          dispatch({ type: 'setContractPeriod', value: ImpicContractPeriod.TRIMESTER_2 });
          break;
        case [7, 8, 9].includes(currentMonth):
          dispatch({ type: 'setContractPeriod', value: ImpicContractPeriod.TRIMESTER_3 });
          break;
        case [10, 11, 12].includes(currentMonth):
          dispatch({ type: 'setContractPeriod', value: ImpicContractPeriod.TRIMESTER_4 });
          break;
        default:
          console.log('Invalid month');
      }

      if (propertyInfo) {
        setMetadata(propertyInfo);
      }

      dispatch({ type: 'setCodPostal1', value: propertyInfo?.codPostal1 ?? '' });
      dispatch({ type: 'setCodPostal2', value: propertyInfo?.codPostal2 ?? '' });
      dispatch({ type: 'setLocalidade', value: propertyInfo?.localidade ?? '' });

      onOpen();
    } catch (error) {
      showError(t('impic.unableToRequestImpic'));
    }
  }, [dispatch, job.jobMetadata, showError, onOpen, t]);

  const close = useCallback(() => {
    dispatch({ type: 'setInitialState', value: {} });
    onClose();
  }, [onClose, dispatch]);

  const fieldsMissing = useCallback(
    (array: RelatedParty[], entityType: string) => {
      const hasMissingFields = array.some(relParty => {
        if (relParty.relationType === RelatedPartyRelationType.PERSON) {
          // Independent buyers/sellers have missing fields
          const missingFields = [];
          if (!relParty.nacionalidade) {
            missingFields.push(t('impic.nacionalidade'));
          }
          if (!relParty.idDocumentType) {
            missingFields.push(t('impic.idDocumentType'));
          }
          if (!relParty.idDocumentNumber) {
            missingFields.push(t('impic.idDocumentNumber'));
          }
          if (!relParty.idDocumentExpiryDate) {
            missingFields.push(t('impic.idDocumentExpiryDate'));
          }

          if (missingFields.length > 0) {
            const missingFieldsMessage = missingFields.join(', ');

            showError(`${t('impic.missingFieldsTitle')} ${entityType} ${relParty.name} - ${missingFieldsMessage}`);
            return true;
          }
          return false;
        } else if (relParty.relationType === RelatedPartyRelationType.COMPANY) {
          // Company doesn't have representantes
          if (!relParty.nestedRelatedParties?.length) {
            showError(`${relParty.name}: ${t('impic.missingRepresentantes')}`);
            return true;
          } else if (relParty.nestedRelatedParties?.length) {
            // Company's representantes have missing fields
            const repHasMissingFields = relParty.nestedRelatedParties.some(rep => {
              const missingFields = [];
              if (!rep.nacionalidade) {
                missingFields.push(t('impic.nacionalidade'));
              }
              if (!rep.idDocumentType) {
                missingFields.push(t('impic.idDocumentType'));
              }
              if (!rep.idDocumentNumber) {
                missingFields.push(t('impic.idDocumentNumber'));
              }
              if (!rep.idDocumentExpiryDate) {
                missingFields.push(t('impic.idDocumentExpiryDate'));
              }

              if (missingFields.length > 0) {
                const missingFieldsMessage = missingFields.join(', ');

                showError(
                  `${t('impic.form.company')} ${relParty.name}: ${t('impic.missingFieldsTitle')} 
                  ${entityType} (${rep.name}) - ${missingFieldsMessage}`,
                );
                return true;
              }
              return false;
            });
            return repHasMissingFields;
          }
        } else if (relParty.relationType === RelatedPartyRelationType.HEIR) {
          // Herança doesn't have heirs
          if (!relParty.nestedRelatedParties?.length) {
            showError(`${relParty.name}: ${t('impic.missingHerdeiros')}`);
            return true;
          } else if (relParty.nestedRelatedParties?.length) {
            // Herança's heirs have missing fields
            const repHasMissingFields = relParty.nestedRelatedParties.some(rep => {
              const missingFields = [];
              if (!rep.nacionalidade) {
                missingFields.push(t('impic.nacionalidade'));
              }
              if (!rep.idDocumentType) {
                missingFields.push(t('impic.idDocumentType'));
              }
              if (!rep.idDocumentNumber) {
                missingFields.push(t('impic.idDocumentNumber'));
              }
              if (!rep.idDocumentExpiryDate) {
                missingFields.push(t('impic.idDocumentExpiryDate'));
              }

              if (missingFields.length > 0) {
                const missingFieldsMessage = missingFields.join(', ');

                showError(
                  `${t('impic.form.inheritance')} ${relParty.name}: ${t('impic.missingFieldsTitle')} 
                  ${entityType} (${rep.name}) - ${missingFieldsMessage}`,
                );
                return true;
              }
              return false;
            });
            return repHasMissingFields;
          }
        }
        return false;
      });
      return hasMissingFields;
    },
    [showError, t],
  );

  const fieldsMissingCPU = useCallback(() => {
    const missingFields = [];
    if (!metadata?.codPostal1.length && !form?.codPostal1) {
      missingFields.push(t('impic.form.codPostal1'));
    }
    if (!metadata?.codPostal2.length && !form?.codPostal2) {
      missingFields.push(t('impic.form.codPostal2'));
    }
    if (!metadata?.localidade.length && !form?.localidade) {
      missingFields.push(t('impic.form.localidade'));
    }

    if (missingFields.length > 0) {
      const missingFieldsMessage = missingFields.join(', ');

      showError(` ${t('impic.form.missingFieldsCPU')} - ${missingFieldsMessage}`);
      return true;
    }
    return false;
  }, [
    form?.codPostal1,
    form?.codPostal2,
    form?.localidade,
    metadata?.codPostal1.length,
    metadata?.codPostal2.length,
    metadata?.localidade.length,
    showError,
    t,
  ]);

  const onSubmit = useCallback(async () => {
    const validationMessages = validate();

    if (
      fieldsMissing(buyers, t('impic.form.buyers')) ||
      fieldsMissing(sellers, t('impic.form.sellers')) ||
      fieldsMissing(heranças, t('impic.form.heirs'))
    ) {
      return;
    }

    if (fieldsMissingCPU()) {
      return;
    }

    if (validationMessages.length > 0) {
      showError(validationMessages.map(i => t(i)).join(', \n'));
      return;
    }
    const bankTransfers: ImpicBankTransferItem[] = formBankTransfers.map(i => ({
      remitterIban: i['transfer:iban:remitter'],
      beneficiaryIban: i['transfer:iban:beneficiary'],
      amountPrice: i.amount,
    }));
    const cheques: ImpicChequeItem[] = formCheques.map(i => ({
      number: i['transfer:cheque:number'],
      drawee: i['transfer:cheque:drawee'],
      amountPrice: i.amount,
    }));
    const dto: ImpicDeclarationData = mapFormToImpicDto(form, bankTransfers, cheques);
    dto.chequesAmountPriceSum = formatPrice!(totalAmountCheques?.toString() ?? '0') + ' €';
    dto.bankTransfersAmountPriceSum = formatPrice!(totalAmountBankTransfers?.toString() ?? '0') + ' €';

    let finalSellers: RightOfFirstRefusalRelatedParty[] = [];
    finalSellers = [
      ...(sellers.map(i => ({ name: i.name, nif: i.nif })) as RightOfFirstRefusalRelatedParty[]),
      ...((isHerdeirosInsteadOfHerança ? heirs : heranças).map(i => ({
        name: i.name,
        nif: i.nif,
      })) as RightOfFirstRefusalRelatedParty[]),
    ];

    if (finalSellers.length > 11 || buyers.length > 11) {
      showError(t('rightOfFirstRefusal.tooManySellers'));
      return;
    }

    try {
      const xml = await buildImpicXml({ jobId: job.id, dto }).unwrap();

      const blob = new Blob([xml], { type: 'application/xml' });

      const downloadUrl = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = downloadUrl;
      const filename = `IMPIC_${job.name}.xml`;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(downloadUrl);

      showSuccess(`${t('impic.xmlGenerated')} (${filename})`);
    } catch (error) {
      showApiError(error);
    }
  }, [
    validate,
    fieldsMissing,
    buyers,
    t,
    sellers,
    heranças,
    fieldsMissingCPU,
    formBankTransfers,
    formCheques,
    form,
    formatPrice,
    totalAmountCheques,
    totalAmountBankTransfers,
    isHerdeirosInsteadOfHerança,
    heirs,
    showError,
    buildImpicXml,
    job.id,
    job.name,
    showSuccess,
    showApiError,
  ]);

  const secondaryBg = useColorModeValue('gray.50', undefined);
  const sectionSubtitleColor = useColorModeValue('gray.500', 'whiteAlpha.600');
  const textColor = useColorModeValue('blue.800', 'white');
  const buttonTextColor = useColorModeValue('blue.800', 'blue.800');
  const totalTitleTextColor = useColorModeValue('blue.900', 'white');
  const menuItemBg = useColorModeValue('yellow.50', '_gray.400');
  const menuItemHoverBg = useColorModeValue('yellow.100', '_gray.300');
  const borderColor = useColorModeValue('_gray.300', '_gray.300');

  const [expandedAccordionIndex, setExpandedAccordionIndex] = useState(0);

  const filteredImpicTransactionQualities = useMemo(() => {
    if (!impicTransactionQualities) {
      return Object.values(ImpicQualidadeDeclaranteNT).map(i => ({
        value: i,
        label: t(tByQualidadeDeclarante[i]),
      }));
    } else {
      const allowedQualities = impicTransactionQualities.split(',');
      return Object.values(ImpicQualidadeDeclaranteNT)
        .filter(i => allowedQualities.includes(i))
        .map(i => ({
          value: i,
          label: t(tByQualidadeDeclarante[i]),
        }));
    }
  }, [impicTransactionQualities, t]);

  return (
    <>
      {isMainButton ? (
        <AppButton
          key={`impic-button`}
          height="40px"
          buttonType={AppButtonType.MAIN}
          onClick={e => {
            e.stopPropagation();
            open();
          }}
          title={t('impic.itemName')}
        />
      ) : (
        <AppButton
          key={`impic-button`}
          buttonType={AppButtonType.PRIMARY}
          colorSchemes={[AppButtonColorScheme.TRANSPARENT]}
          bg={menuItemBg}
          leftIcon={<RepairingTool boxSize={5} color={buttonTextColor} />}
          borderColor={borderColor}
          onClick={open}
          color={buttonTextColor}
          _hover={{ bg: menuItemHoverBg }}
        >
          {t('impic.itemName')}
        </AppButton>
      )}

      <Modal size="xl" isOpen={isOpen} onClose={close} isCentered scrollBehavior="inside" id="impic-modal">
        <ModalOverlay />
        <ModalContent color={textColor} maxH={'calc(100% - 0rem)'} maxW={'900px'} p="24px" borderRadius="16px">
          <ModalHeader p="0" fontSize="24px">
            {t('impic.form.title')}
          </ModalHeader>
          <ModalCloseButton right="24px" top="none" />
          <ModalBody p="24px 3px">
            <Accordion
              index={expandedAccordionIndex}
              allowMultiple={false}
              allowToggle
              onChange={index => setExpandedAccordionIndex(index as number)}
            >
              <AccordionItem color={textColor} bg={expandedAccordionIndex === 0 ? secondaryBg : undefined}>
                <AccordionButton _focus={{ boxShadow: 'none' }} p={'16px'} onClick={() => setExpandedAccordionIndex(0)}>
                  <Flex flex="1" gap={'16px'} height={'32px'} alignItems={'center'}>
                    <Flex direction={'column'} alignItems="flex-start">
                      <Text fontSize={'18px'} fontWeight="bold">
                        {t('impic.form.buyers')}
                      </Text>

                      <Text fontSize={'14px'} color={sectionSubtitleColor}>
                        <Trans
                          i18nKey="impic.toEditThePartiesBelowPleaseGoToTheContractsPartiesPage"
                          components={{
                            linkToPage: (
                              <Link
                                style={{
                                  fontWeight: 'bold',
                                  textDecoration: 'underline',
                                }}
                                to={`/jobs/${job.id}/contracts-and-parties`}
                                onClick={close}
                              ></Link>
                            ),
                          }}
                        ></Trans>
                      </Text>
                    </Flex>
                  </Flex>
                  <AccordionIcon />
                </AccordionButton>

                <AccordionPanel p="8px 16px 16px 16px">
                  <hr />
                  <Flex py={'12px'} direction={'column'} gap="16px" maxH={'400px'} overflowY="auto">
                    {buyers.map(i => (
                      <Flex key={i.id} alignItems={'center'} gap={'24px'}>
                        <FormInputField
                          label={t('impic.form.name')}
                          value={i.name}
                          onChange={() => ({})}
                          isReadonly={true}
                        />
                        <FormInputField
                          label={t('impic.form.nifNips')}
                          onChange={value =>
                            onNifChange(i.entityType, {
                              id: i.id,
                              nif: value,
                            })
                          }
                          value={i.nif}
                          isReadonly={true}
                        />
                      </Flex>
                    ))}
                  </Flex>
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem color={textColor} bg={expandedAccordionIndex === 1 ? secondaryBg : undefined}>
                <AccordionButton _focus={{ boxShadow: 'none' }} p={'16px'} onClick={() => setExpandedAccordionIndex(1)}>
                  <Flex flex="1" gap={'16px'} height={'32px'} alignItems={'center'}>
                    <Flex direction={'column'} alignItems="flex-start">
                      <Text fontSize={'18px'} fontWeight="bold">
                        {t('impic.form.sellers')}
                      </Text>

                      <Text fontSize={'14px'} color={sectionSubtitleColor}>
                        <Trans
                          i18nKey="impic.toEditThePartiesBelowPleaseGoToTheContractsPartiesPage"
                          components={{
                            linkToPage: (
                              <Link
                                style={{
                                  fontWeight: 'bold',
                                  textDecoration: 'underline',
                                }}
                                to={`/jobs/${job.id}/contracts-and-parties`}
                                onClick={close}
                              ></Link>
                            ),
                          }}
                        ></Trans>
                      </Text>
                    </Flex>
                  </Flex>
                  <AccordionIcon />
                </AccordionButton>

                <AccordionPanel p="8px 16px 16px 16px">
                  <Flex py="12px" direction="column" gap="16px" maxH="400px" overflowY="auto">
                    {(isHerdeirosInsteadOfHerança && heirs.length ? heirs : heranças).map(i => (
                      <Flex key={i.id} alignItems="center" gap="24px">
                        <FormInputField
                          label={t('impic.form.name')}
                          value={i.name}
                          isReadonly={true}
                          onChange={() => ({})}
                        />
                        <FormInputField
                          label={t('impic.form.nifNips')}
                          value={i.nif}
                          isReadonly={true}
                          onChange={value =>
                            onNifChange(i.entityType, {
                              id: i.id,
                              nif: value,
                            })
                          }
                        />
                      </Flex>
                    ))}
                    {sellers.map(i => (
                      <Flex key={i.id} alignItems="center" gap="24px">
                        <FormInputField
                          label={t('impic.form.name')}
                          value={i.name}
                          isReadonly={true}
                          onChange={() => ({})}
                        />
                        <FormInputField
                          label={t('impic.form.nifNips')}
                          value={i.nif}
                          isReadonly={true}
                          onChange={value =>
                            onNifChange(i.entityType, {
                              id: i.id,
                              nif: value,
                            })
                          }
                        />
                      </Flex>
                    ))}
                  </Flex>
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem color={textColor} bg={expandedAccordionIndex === 2 ? secondaryBg : undefined}>
                <AccordionButton _focus={{ boxShadow: 'none' }} p={'16px'} onClick={() => setExpandedAccordionIndex(2)}>
                  <Flex
                    flex="1"
                    gap={'16px'}
                    height={'32px'}
                    p="0 16px 0 0"
                    alignItems={'center'}
                    justifyContent={'space-between'}
                  >
                    <Text fontSize={'18px'} fontWeight="bold">
                      {t('impic.form.bankTransfers.title')}
                    </Text>

                    {expandedAccordionIndex === 2 && (
                      <AppButton
                        buttonType={AppButtonType.SMALL}
                        onClick={e => {
                          e.preventDefault();
                          e.stopPropagation();
                          dispatchBankTransfers({ type: 'addItem' });
                        }}
                        colorSchemes={[AppButtonColorScheme.DEFAULT]}
                        leftIcon={<Plus w="12px" h="12px" />}
                        title={t('add')}
                        tooltip={t('add')}
                      />
                    )}
                  </Flex>
                  <AccordionIcon />
                </AccordionButton>

                <AccordionPanel p="0 16px 16px 16px" overflowX={'auto'}>
                  <Box minW={'550px'}>
                    <TableInlineEdit<string | number>
                      columns={[
                        {
                          columnKey: 'paymentAccount',
                          title: t('impic.form.bankTransfers.paymentAccountIban'),
                          minWidthPercent: 38,
                        },
                        {
                          columnKey: 'beneficiaryAccount',
                          title: t('impic.form.bankTransfers.beneficiaryAccountIban'),
                          minWidthPercent: 38,
                        },
                        {
                          columnKey: 'transferValue',
                          title: t('impic.form.bankTransfers.transferValue'),
                          minWidthPercent: 20,
                        },
                        { columnKey: 'actions', title: '', minWidthPercent: 4 },
                      ]}
                      rows={formBankTransfers.map((item, index) => ({
                        fields: [
                          {
                            type: TableInlineEditColumnType.IBAN,
                            value: item['transfer:iban:remitter'],
                            onBlurOrValid: value =>
                              dispatchBankTransfers({
                                type: 'setIbanRemitter',
                                value: { index, value: value as string },
                              }),
                          },
                          {
                            type: TableInlineEditColumnType.IBAN,
                            value: item['transfer:iban:beneficiary'],
                            onBlurOrValid: value =>
                              dispatchBankTransfers({
                                type: 'setIbanBeneficiary',
                                value: { index, value: value as string },
                              }),
                          },
                          {
                            type: TableInlineEditColumnType.PRICE,
                            value: item.amount,
                            onBlur: value =>
                              dispatchBankTransfers({ type: 'setAmount', value: { index, value: value as number } }),
                          },
                        ],
                        rightActions: (
                          <AppButton
                            buttonType={AppButtonType.ICON}
                            onClick={() => dispatchBankTransfers({ type: 'removeItem', index })}
                            colorSchemes={[AppButtonColorScheme.DELETE_ICON]}
                            children={<RecycleBing boxSize={5} />}
                            tooltip={t('delete')}
                          />
                        ),
                      }))}
                    />

                    <Grid templateColumns="76% 24%" gap="0" alignItems={'center'}>
                      <Text fontSize={'16px'} fontWeight="700" textAlign={'end'} color={totalTitleTextColor}>
                        {t('Total')}
                      </Text>
                      <PriceInputField
                        hideBorders
                        isReadonly
                        value={totalAmountBankTransfers}
                        opacity="1"
                        thousandSeparator={' '}
                        decimalSeparator={','}
                        paddingRight="0"
                      />
                    </Grid>
                  </Box>
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem color={textColor} bg={expandedAccordionIndex === 3 ? secondaryBg : undefined}>
                <AccordionButton _focus={{ boxShadow: 'none' }} p={'16px'} onClick={() => setExpandedAccordionIndex(2)}>
                  <Flex
                    flex="1"
                    gap={'16px'}
                    height={'32px'}
                    p="0 16px 0 0"
                    alignItems={'center'}
                    justifyContent={'space-between'}
                  >
                    <Text fontSize={'18px'} fontWeight="bold">
                      {t('impic.form.cheques.title')}
                    </Text>

                    {expandedAccordionIndex === 3 && (
                      <AppButton
                        buttonType={AppButtonType.SMALL}
                        onClick={e => {
                          e.preventDefault();
                          e.stopPropagation();
                          dispatchCheques({ type: 'addItem' });
                        }}
                        colorSchemes={[AppButtonColorScheme.DEFAULT]}
                        leftIcon={<Plus w="12px" h="12px" />}
                        title={t('add')}
                        tooltip={t('add')}
                      />
                    )}
                  </Flex>
                  <AccordionIcon />
                </AccordionButton>

                <AccordionPanel p="0 16px 16px 16px" overflowX={'auto'}>
                  <Box minW={'550px'}>
                    <TableInlineEdit<string | number>
                      columns={[
                        { columnKey: 'chequeNumber', title: t('impic.form.cheques.chequeNumber'), minWidthPercent: 30 },
                        { columnKey: 'chequeDrawee', title: t('impic.form.cheques.chequeDrawee'), minWidthPercent: 46 },
                        {
                          columnKey: 'transferValue',
                          title: t('impic.form.cheques.transferValue'),
                          minWidthPercent: 20,
                        },
                        { columnKey: 'actions', title: '', minWidthPercent: 4 },
                      ]}
                      rows={formCheques.map((item, index) => ({
                        fields: [
                          {
                            type: TableInlineEditColumnType.NUMBER,
                            value: item['transfer:cheque:number'],
                            onChange: value =>
                              dispatchCheques({
                                type: 'setChequeNumber',
                                value: { index, value: value as number },
                              }),
                          },
                          {
                            type: TableInlineEditColumnType.STRING,
                            value: item['transfer:cheque:drawee'],
                            onChange: value =>
                              dispatchCheques({
                                type: 'setChequeDrawee',
                                value: { index, value: value as string },
                              }),
                          },
                          {
                            type: TableInlineEditColumnType.PRICE,
                            value: item.amount,
                            onBlur: value =>
                              dispatchCheques({ type: 'setAmount', value: { index, value: value as number } }),
                          },
                        ],
                        rightActions: (
                          <AppButton
                            buttonType={AppButtonType.ICON}
                            onClick={() => dispatchCheques({ type: 'removeItem', index })}
                            colorSchemes={[AppButtonColorScheme.DELETE_ICON]}
                            children={<RecycleBing boxSize={5} />}
                            tooltip={t('delete')}
                          />
                        ),
                      }))}
                    />

                    <Grid templateColumns="76% 24%" gap="0" alignItems={'center'}>
                      <Text fontSize={'16px'} fontWeight="700" textAlign={'end'} color={totalTitleTextColor}>
                        {t('Total')}
                      </Text>
                      <PriceInputField
                        hideBorders
                        isReadonly
                        value={totalAmountCheques}
                        opacity="1"
                        thousandSeparator={' '}
                        decimalSeparator={','}
                        paddingRight="0"
                      />
                    </Grid>
                  </Box>
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem color={textColor} bg={expandedAccordionIndex === 4 ? secondaryBg : undefined}>
                <AccordionButton _focus={{ boxShadow: 'none' }} p={'16px'} onClick={() => setExpandedAccordionIndex(2)}>
                  <Flex flex="1" gap={'16px'} height={'32px'} alignItems={'center'}>
                    <Text fontSize={'18px'} fontWeight="bold">
                      {t('impic.form.transactionElements')}
                    </Text>
                  </Flex>
                  <AccordionIcon />
                </AccordionButton>

                <AccordionPanel p="8px 16px 16px 16px">
                  <hr />
                  <Grid
                    py={'12px'}
                    columnGap={'24px'}
                    rowGap={'16px'}
                    gridTemplateColumns={{ sm: '1fr', md: '1fr 1fr' }}
                  >
                    <FormControl>
                      <FormLabel mb="4px">{t('impic.form.qualidadeNaTransacao')}</FormLabel>
                      {filteredImpicTransactionQualities.length === 1 ? (
                        <Text>{t(tByQualidadeDeclarante[filteredImpicTransactionQualities[0].value])}</Text>
                      ) : (
                        <SelectInputInputField<ImpicQualidadeDeclaranteNT>
                          items={filteredImpicTransactionQualities}
                          value={form.qualidadeDeclarante}
                          placeholder={t('impic.form.qualidadeNaTransacao')}
                          onChange={e => dispatch({ type: 'setQualidadeNaTransacao', value: e })}
                        />
                      )}
                    </FormControl>

                    <FormInputField
                      label={t('impic.form.contractYear')}
                      value={form.contractYear}
                      onChange={value => dispatch({ type: 'setContractYear', value })}
                    />

                    <FormControl>
                      <FormLabel mb="4px">{t('impic.form.contractPeriod')}</FormLabel>
                      <SelectInputInputField<ImpicContractPeriod>
                        items={Object.values(ImpicContractPeriod).map(i => ({
                          value: i,
                          label: t(tByContractPeriod[i]),
                        }))}
                        value={form.contractPeriod}
                        placeholder={t('impic.form.contractPeriod')}
                        onChange={e => dispatch({ type: 'setContractPeriod', value: e })}
                      />
                    </FormControl>

                    <FormControl>
                      <FormLabel mb="4px">{t('impic.form.transactionType')}</FormLabel>
                      <SelectInputInputField<ImpicTransactionType>
                        items={Object.values(ImpicTransactionType).map(i => ({
                          value: i,
                          label: t(tByTransactionType[i]),
                        }))}
                        value={form.transactionType}
                        placeholder={t('impic.form.transactionType')}
                        onChange={e => dispatch({ type: 'setTransactionType', value: e })}
                      />
                    </FormControl>

                    <FormControl>
                      <FormLabel mb="4px">{t('impic.form.transactionDate')}</FormLabel>
                      <VCDatePicker
                        placeholder={t('impic.form.transactionDate')}
                        selectedValue={form.transactionDate ? new Date(form.transactionDate) : undefined}
                        onChange={e => {
                          if (e) {
                            dispatch({
                              type: 'setTransactionDate',
                              value: formatDateToYYYYMMDD(e),
                            });
                          }
                        }}
                        portalId={'chakra-modal-impic-modal'}
                        maxDate={new Date()}
                      />
                    </FormControl>

                    <FormControl>
                      <FormInputField.Price
                        label={t('impic.form.property.value') + ' €'}
                        value={Number(form.propertyValue)}
                        onChange={value => dispatch({ type: 'setPropertyValue', value: value.toString() })}
                        thousandSeparator={' '}
                        decimalSeparator={','}
                        useFormattedValue={true}
                      />
                    </FormControl>
                  </Grid>
                </AccordionPanel>
              </AccordionItem>

              {(!metadata?.codPostal1.length || !metadata?.codPostal2.length || !metadata?.localidade.length) && (
                <AccordionItem color={textColor} bg={expandedAccordionIndex === 5 ? secondaryBg : undefined}>
                  <AccordionButton
                    _focus={{ boxShadow: 'none' }}
                    p={'16px'}
                    onClick={() => setExpandedAccordionIndex(3)}
                  >
                    <Flex flex="1" gap={'16px'} height={'32px'} alignItems={'center'}>
                      <Text fontSize={'18px'} fontWeight="bold">
                        {t('impic.form.missingFieldsCPU')}
                      </Text>
                    </Flex>
                    <AccordionIcon />
                  </AccordionButton>

                  <AccordionPanel p="8px 16px 16px 16ƒpx">
                    <hr />
                    <Grid
                      py={'12px'}
                      columnGap={'24px'}
                      rowGap={'16px'}
                      gridTemplateColumns={{ sm: '1fr', md: '1fr 0.01fr 1fr 2fr' }}
                      alignItems={'center'}
                    >
                      <FormControl>
                        <FormInputField
                          type="number"
                          label={t('impic.form.codPostal1')}
                          value={form.codPostal1}
                          onChange={value => dispatch({ type: 'setCodPostal1', value })}
                          maxLength={4}
                        />
                      </FormControl>
                      <Text fontWeight="bold" fontSize="xl" mt="16px">
                        -
                      </Text>
                      <FormControl>
                        <FormInputField
                          type="number"
                          label={t('impic.form.codPostal2')}
                          value={form.codPostal2}
                          onChange={value => dispatch({ type: 'setCodPostal2', value })}
                          maxLength={3}
                        />
                      </FormControl>
                      <FormControl>
                        <FormInputField
                          label={t('impic.form.localidade')}
                          value={form.localidade}
                          onChange={value => dispatch({ type: 'setLocalidade', value })}
                        />
                      </FormControl>
                    </Grid>
                  </AccordionPanel>
                </AccordionItem>
              )}
            </Accordion>
          </ModalBody>

          <ModalFooter justifyContent="center" gap="24px" p="0" position={'relative'}>
            <AppButton
              isLoading={isLoadingInitJobParties}
              onClick={onSubmit}
              title={t('submit')}
              buttonType={AppButtonType.PRIMARY_MAIN}
            />

            <Flex alignItems={'center'} position={'absolute'} right={'0'}>
              <Text fontSize={'16px'} fontWeight="700" textAlign={'end'} color={totalTitleTextColor}>
                {t('Total')}
              </Text>
              <PriceInputField
                width={'160px'}
                hideBorders
                isReadonly
                value={totalAmountCheques + totalAmountBankTransfers}
                opacity="1"
                thousandSeparator={' '}
                decimalSeparator={','}
              />
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
