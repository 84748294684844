import { useTranslation } from 'react-i18next';
import { AppButton, AppButtonColorScheme, AppButtonType, Mortarboard } from 'libs/ui/atoms/src';
import { environment } from '../../environments/environment';

export const VeriKnowsButton = () => {
  const { t } = useTranslation();

  const goToVeriKnows = () => window.open(environment.veriKnowsUrl, '_blank');

  return (
    <>
      <AppButton
        buttonType={AppButtonType.SECONDARY}
        onClick={goToVeriKnows}
        children={<Mortarboard w="24px" h="24px" />}
        tooltip={t('veriKnows')}
        colorSchemes={[AppButtonColorScheme.LEMON]}
      />
    </>
  );
};
