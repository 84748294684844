import { ChangeEvent, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import {
  Checkbox,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  StyleProps,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { friendlyFormatIBAN, electronicFormatIBAN } from 'ibantools';
import { getRawIndexFromCaret, isIbanValid, moveCaret } from '../../../../../../utils';
import { useTranslation } from 'react-i18next';

function sanitizeIbanInput(value: string) {
  return value
    .toUpperCase()
    .replace(/\s+/g, '')
    .replace(/[^A-Z0-9]/g, '');
}

export interface IBANInputFieldProps extends StyleProps {
  value?: string;
  label?: string;

  onChange?: (value: string) => void;
  onBlur?: (value: string) => void;
  /**
   * Called when the input loses focus OR every change when the value is valid
   */
  onBlurOrValid?: (value: string) => void;

  isReadonly?: boolean;
  isRequired?: boolean;
  isInvalid?: boolean;
  hideLabel?: boolean;
  hideError?: boolean;

  leftChildren?: ReactNode;

  maxLength?: number;

  showNotIbanCheckbox?: boolean;
  isNotIbanCheckboxChecked?: boolean;
  onNotIbanCheckboxChange?: (value: boolean) => void;
}

export const IBANInputField = ({
  value,
  label,
  onChange,
  onBlur,
  onBlurOrValid,
  isReadonly,
  isInvalid,
  isRequired,
  hideLabel,
  hideError,
  leftChildren,
  maxLength,
  showNotIbanCheckbox = false,
  isNotIbanCheckboxChecked = false,
  onNotIbanCheckboxChange,
  ...styleProps
}: IBANInputFieldProps) => {
  const { t } = useTranslation();

  const [accountIsNotIBAN, setAccountIsNotIBAN] = useState(false);

  const [formattedValue, setFormattedValue] = useState<string>(friendlyFormatIBAN(value) || '');

  const [error, setError] = useState<string | null>(null);

  const inputRef = useRef<HTMLInputElement>(null);

  const textColor = useColorModeValue('navy.750', 'white');
  const errorBorderColor = useColorModeValue('red.500', 'red.500');

  useEffect(() => {
    if (isNotIbanCheckboxChecked) {
      setAccountIsNotIBAN(isNotIbanCheckboxChecked);
    }
  }, [isNotIbanCheckboxChecked]);

  const validateAsIBAN = useCallback(
    (rawInput: string) => {
      const sanitized = sanitizeIbanInput(rawInput);
      const isValid = sanitized ? isIbanValid(sanitized) : !isRequired;
      setError(isValid ? null : t('ibanIsNotValid'));
      return isValid;
    },
    [isRequired, t],
  );

  const updateValueAndError = useCallback(
    (val?: string) => {
      const rawVal = val || '';
      if (accountIsNotIBAN) {
        setFormattedValue(rawVal);
        setError(null);
      } else {
        const newValue = friendlyFormatIBAN(rawVal) || '';
        setFormattedValue(newValue);
        validateAsIBAN(newValue);
      }
    },
    [accountIsNotIBAN, validateAsIBAN],
  );

  useEffect(() => {
    updateValueAndError(value);
  }, [accountIsNotIBAN, value, updateValueAndError]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (isReadonly) return;

    const caretPos = e.target.selectionStart ?? 0;

    if (accountIsNotIBAN) {
      const freeFormValue = e.target.value;
      if (maxLength && freeFormValue.length > maxLength) {
        return;
      }
      setFormattedValue(freeFormValue);
      setError(null);

      onChange?.(freeFormValue);
      moveCaret(inputRef.current, caretPos);
      return;
    }

    const rawIban = sanitizeIbanInput(e.target.value);
    if (maxLength && rawIban.length > maxLength) {
      return;
    }

    const rawIndex = getRawIndexFromCaret(e.target.value, caretPos);

    const newFormattedValue = friendlyFormatIBAN(rawIban) || '';
    const isValid = validateAsIBAN(newFormattedValue);

    setFormattedValue(newFormattedValue);
    onChange?.(electronicFormatIBAN(rawIban) || rawIban);

    if (isValid) {
      onBlurOrValid?.(electronicFormatIBAN(rawIban) || rawIban);
    }

    const rawSubstringBeforeCaret = rawIban.slice(0, rawIndex);
    const formattedBeforeCaret = friendlyFormatIBAN(rawSubstringBeforeCaret) || '';
    const newCaretPos = formattedBeforeCaret.length;

    moveCaret(inputRef.current, newCaretPos);
  };

  const handleBlur = (e: ChangeEvent<HTMLInputElement>) => {
    if (isReadonly) return;

    const rawValue = e.target.value;
    if (accountIsNotIBAN) {
      onBlur?.(rawValue);
      onBlurOrValid?.(rawValue);
      return;
    }

    const sanitized = sanitizeIbanInput(rawValue);
    onBlur?.(electronicFormatIBAN(sanitized) || sanitized);
    onBlurOrValid?.(electronicFormatIBAN(sanitized) || sanitized);
  };

  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    setAccountIsNotIBAN(checked);
    onNotIbanCheckboxChange?.(checked);
  };

  return (
    <FormControl maxW={'100%'} overflow="hidden" p="1px">
      {!hideLabel && label && (
        <FormLabel mb="4px" maxW={'100%'} overflow="hidden" display={'flex'} title={label}>
          <Text className="no-text-wrap" as="span" textOverflow={'ellipsis'} overflow="hidden">
            {label}
          </Text>
          {isRequired && (
            <Text as={'span'} color={'red'}>
              {' *'}
            </Text>
          )}
        </FormLabel>
      )}

      <InputGroup>
        {leftChildren && (
          <InputLeftElement pointerEvents="none" height={'100%'}>
            {leftChildren}
          </InputLeftElement>
        )}
        <Input
          ref={inputRef}
          height={'50px'}
          borderColor={isInvalid || error ? errorBorderColor : undefined}
          borderRadius={'10px'}
          color={textColor}
          placeholder={label}
          value={formattedValue}
          onChange={handleChange}
          isInvalid={!!error}
          readOnly={isReadonly}
          textOverflow={'ellipsis'}
          onBlur={handleBlur}
          {...styleProps}
        />
      </InputGroup>
      {!hideError && error && (
        <Text color={errorBorderColor} fontSize="sm">
          {error}
        </Text>
      )}
      {showNotIbanCheckbox && (
        <Checkbox mt={2} isChecked={accountIsNotIBAN} onChange={handleCheckboxChange}>
          {t('accountIsNotIban')}
        </Checkbox>
      )}
    </FormControl>
  );
};
