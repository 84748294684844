import { api } from './api';

export enum UserRole {
  MASTER_USER = 'MASTER_USER',
  REAL_ESTATE_AGENT = 'REAL_ESTATE_AGENT',
  REAL_ESTATE_AGENT_JUNIOR = 'REAL_ESTATE_AGENT_JUNIOR',
  REAL_ESTATE_AGENT_ASSISTANT = 'REAL_ESTATE_AGENT_ASSISTANT',
  ADMINISTRATIVE = 'ADMINISTRATIVE',
  LEGAL = 'LEGAL',
  LEGAL_JUNIOR = 'LEGAL_JUNIOR',
  WORK_MANAGER = 'WORK_MANAGER',
}

export enum UserStatus {
  INVITED = 'INVITED',
  ACTIVE = 'ACTIVE',
}

export interface UserSimple {
  id: string;
  name: string;
  email: string;
  createdAt: string;
  updatedAt: string;
  organizations?: Organization[] | null;
  status?: UserStatus;
  isPersonalEnabled: boolean;
  roles: UserRole[];

  nifNips?: string;
  address?: string;
  postalCode?: string;
  location?: string;
}

export interface User extends UserSimple {
  jobCount: number;
  documentCount: number;
  contractBalance: number;
}

export interface Organization {
  address: string | null;
  amiLicense: string | null;
  balanceEur: number;
  costPerContractUse: number;
  costPerReport: number;
  createdAt: Date;
  createdBy: string;
  id: string;
  impicTransactionQualities: string | null;
  isUnlimitedUsage: boolean;
  location: string | null;
  name: string;
  nifNipc: string | null;
  ownerId: string;
  parentId: string | null;
  postalCode: string | null;
  updatedAt: Date;
  updatedBy: string;
}

export interface UserInviteDto {
  email: string;
  name: string;

  nifNips?: string;
  address?: string;
  postalCode?: string;
  location?: string;

  roles: UserRole[];
  locale: string;
}

export interface UserResendInviteDto {
  userId: string;
  locale: string;
}

export interface UserUpdateDto {
  id: string;

  name?: string;

  nifNips?: string;
  address?: string;
  postalCode?: string;
  location?: string;
}

export interface UserSetRolesDto {
  userId: string;
  roles: UserRole[];
}

const userPrivateApi = api.injectEndpoints({
  endpoints: build => ({
    me: build.query<User, object>({
      query: () => 'user/me',
    }),
    organizationUsers: build.query<UserSimple[], { search?: string }>({
      query: ({ search }) => ({ url: 'user/organizationUsers', params: { search } }),
    }),
    inviteUser: build.mutation<UserSimple, UserInviteDto>({
      query: body => ({ url: 'user/invite', body, method: 'POST' }),
    }),
    updateUser: build.mutation<UserSimple, UserUpdateDto>({
      query: body => ({ url: 'user', body, method: 'PUT' }),
    }),
    setUserRoles: build.mutation<UserSimple, UserSetRolesDto>({
      query: body => ({ url: 'user/roles', body, method: 'POST' }),
    }),
    deleteUser: build.mutation<UserSimple, { id: string }>({
      query: ({ id }) => ({ url: 'user/' + id, method: 'DELETE' }),
    }),
    userResendInvite: build.mutation<UserSimple, UserResendInviteDto>({
      query: body => ({ url: 'user/resendInvite', body, method: 'POST' }),
    }),
  }),
});

export const {
  useMeQuery: useMePrivateQuery,
  useLazyMeQuery: useLazyMePrivateQuery,
  useOrganizationUsersQuery,
  useLazyOrganizationUsersQuery,
  useInviteUserMutation,
  useUpdateUserMutation,
  useSetUserRolesMutation,
  useDeleteUserMutation,
  useUserResendInviteMutation,
  endpoints: UserPrivateEndpoints,
} = userPrivateApi;
