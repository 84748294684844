import React, { useState, useEffect } from 'react';
import { Box, Breadcrumb, BreadcrumbItem, Button, Flex, Text, useColorModeValue } from '@chakra-ui/react';
import { AdminNavbarLinks } from 'components/navbar/NavbarLinksMy';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { JobShareButton } from 'components/jobShareButton/JobShareButton';
import { DownloadAllFilesButton } from 'components/downloadAllFilesButton/DownloadAllFilesButton';
import { AddContractsButton } from 'components/addContractsButton/AddContractsButton';
import { BackArrow } from '../../libs/ui/atoms/src';
import { useLocation, useMatch, useNavigate } from 'react-router-dom';
import { JobSignButton } from '../jobSignButton/JobSignButton';
import { WhatsAppHelpButton } from '../whatsAppHelpButton/WhatsAppHelpButton';
import { VeriKnowsButton } from '../veriKnowsButton/VeriKnowsButton';
import { VCSearch } from '../../libs/ui/atoms/src/lib/search/Search';
import { useDispatch } from 'react-redux';
import { ListPageType, setSearch } from '../../store/list-page-params';
import { useTypedSelector } from '../../store';
import { useDebounce } from '../../hooks/useDebounce';

export function AdminNavbar(props: {
  secondary: boolean;
  message: string | boolean;
  brandText: string;
  logoText: string;
  fixed: boolean;
  routes: RoutesType[];
  onClickButton?: React.MouseEventHandler<HTMLButtonElement>;
  onOpen: (...args: any[]) => any;
}) {
  const location = useLocation();
  const navigate = useNavigate();
  const isRootLocation = useMatch('/home');

  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', changeNavbar);

    return () => {
      window.removeEventListener('scroll', changeNavbar);
    };
  });
  const { secondary, brandText } = props;

  const navbarFilter = 'none';
  const navbarBackdrop = 'blur(20px)';
  const navbarShadow = 'none';
  const navbarBg = useColorModeValue('rgba(244, 247, 254, 0.2)', 'rgba(11,20,55,0.5)');
  const navbarBorder = 'transparent';
  const gap = '0px';
  const bg = useColorModeValue('white', '#1B254B');
  const changeNavbar = () => {
    if (window.scrollY > 1) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  const backArrowColor = useColorModeValue('_brand.550', 'white');
  const breadcrumbColor = useColorModeValue('_brand.800', 'white');

  const dispatch = useDispatch();
  const [query, setQuery] = useState('');
  const isJobList = useMatch('/jobs');
  const isSigningList = useMatch('/signings');

  const listParams = useTypedSelector(state => state.listParams);

  const debouncedQuery = useDebounce(query, { delay: 500 });

  useEffect(() => {
    if (isJobList) {
      setQuery(listParams.search[ListPageType.JOB_LIST]);
    }
    if (isSigningList) {
      setQuery(listParams.search[ListPageType.SIGNING_LIST]);
    }
  }, [isJobList, isSigningList]);

  useEffect(() => {
    if (isJobList) {
      dispatch(setSearch({ pageType: ListPageType.JOB_LIST, query: debouncedQuery }));
    } else if (isSigningList) {
      dispatch(setSearch({ pageType: ListPageType.SIGNING_LIST, query: debouncedQuery }));
    }
  }, [debouncedQuery]);

  const isJobsPage = useMatch('/jobs/:jobId/');

  const handleBack = () => {
    if (isJobsPage) {
      navigate(`/jobs`);
    } else {
      navigate(-1);
    }
  };

  return (
    <Box
      className="admin-navbar"
      position={'sticky'}
      top={0}
      boxShadow={navbarShadow}
      // bg={navbarBg}
      borderColor={navbarBorder}
      filter={navbarFilter}
      backdropFilter={navbarBackdrop}
      backgroundPosition="center"
      backgroundSize="cover"
      borderWidth="1.5px"
      borderStyle="solid"
      transitionDelay="0s, 0s, 0s, 0s"
      transitionDuration=" 0.25s, 0.25s, 0.25s, 0s"
      transition-property="box-shadow, background-color, filter, border"
      transitionTimingFunction="linear, linear, linear, linear"
      alignItems={{ xl: 'center' }}
      display={secondary ? 'block' : 'flex'}
      justifyContent={{ xl: 'center' }}
      lineHeight="25.6px"
      mx="auto"
      w="100%"
      p={{ base: '10px', md: '24px' }}
    >
      <Flex
        w="100%"
        justifyContent="space-between"
        flexDirection={{
          sm: 'column',
          md: 'row',
        }}
        alignItems={{
          sm: 'flex-end',
          md: 'center',
        }}
        mb={gap}
        gap="16px"
      >
        <Flex
          flex="1"
          flexDirection={{ sm: 'column', md: 'row' }}
          alignItems={{ sm: 'flex-end', md: 'center' }}
          gap={'16px'}
        >
          <Flex mb={{ sm: '8px', md: '0px' }} gap="16px" alignItems={'center'}>
            {(!isRootLocation || location.state?.from) && (
              <Button
                leftIcon={<BackArrow />}
                iconSpacing={'0'}
                borderRadius={'10px'}
                color={backArrowColor}
                p={'0 12px'}
                bg={'transparent'}
                onClick={handleBack}
              ></Button>
            )}

            <Breadcrumb
              spacing="8px"
              fontSize="24px"
              fontWeight={700}
              separator={<ChevronRightIcon color="gray.500" />}
            >
              <BreadcrumbItem>
                <Text
                  color={breadcrumbColor}
                  textDecoration="none"
                  _hover={{ textDecoration: 'none' }}
                  cursor="default"
                >
                  {brandText}
                </Text>
              </BreadcrumbItem>
            </Breadcrumb>
          </Flex>

          {!!isJobList && (
            <VCSearch
              isFlexible={true}
              search={query}
              onSearch={query => {
                setQuery(query);
              }}
            />
          )}
        </Flex>

        <Flex
          direction={{ base: 'column', md: 'row' }}
          justifyContent={{ base: 'flex-end', md: 'center' }}
          alignItems={{ base: 'flex-end', md: 'center' }}
          flexWrap={{ base: 'wrap', md: 'unset' }}
          w={{ base: '100%', md: 'unset' }}
          mt={{ base: '16px', md: '0px' }}
          gap="16px"
        >
          <Flex gap="16px" order={{ base: 1, md: 0 }} alignItems="center">
            <DownloadAllFilesButton />
            <JobShareButton />
            <AddContractsButton />
            <JobSignButton />
          </Flex>

          <Flex gap="16px" flexWrap={{ base: 'wrap-reverse', md: 'unset' }} justifyContent="flex-end">
            <Flex gap="16px">
              <WhatsAppHelpButton />
              <VeriKnowsButton />
              <AdminNavbarLinks
                onOpen={props.onOpen}
                secondary={props.secondary}
                fixed={props.fixed}
                routes={props.routes}
                onClickButton={props.onClickButton}
              />
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
}
