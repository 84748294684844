import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Flex, Spinner } from '@chakra-ui/react';
import { JobDetailsWidget } from './widgets/JobDetailsWidget/JobDetailsWidget';
import {
  DocumentType,
  JobStatus,
  useGetJobPrivateQuery,
  useLazyGetJobPrivateQuery,
  useSubmitJobMutation,
  useUpdateJobMutation,
  JobDocument,
  JobSubmitParamsDto,
  JobMode,
} from '../../../api';
import { useTypedSelector } from '../../../store';
import { selectJob, selectJobDocuments } from '../../../store/job';
import { useDidUpdateEffect } from 'hooks/useDidUpdateEffect';
import { useTranslation } from 'react-i18next';
import { ErrorDisplay } from 'libs/ui/atoms/src/lib/errorDisplay/ErrorDisplay';
import { useAppToast } from '../../../libs/ui/hooks';

export function JobDetailPage() {
  const { jobId } = useParams<{ jobId: string }>();
  const { showApiError } = useAppToast();
  const { t } = useTranslation();
  const { isLoading, error, data } = useGetJobPrivateQuery(
    { jobId: jobId ?? '' },
    {
      refetchOnMountOrArgChange: true,
      refetchOnReconnect: true,
      refetchOnFocus: true,
    },
  );
  const [submitJob, { isLoading: isLoadingSubmit, error: errorSubmit }] = useSubmitJobMutation();
  const [updateJob] = useUpdateJobMutation();

  const job = useTypedSelector(state => (jobId ? selectJob(state, jobId) : null));
  const jobDocuments = useTypedSelector(state => selectJobDocuments(state, jobId ?? ''));
  const certidaoPermanenteDoc = jobDocuments.find(i => i.type === DocumentType.CERTIDAO_PERMANENTE);

  const [getJob] = useLazyGetJobPrivateQuery({ pollingInterval: job?.status === JobStatus.Submitted ? 700 : 0 });

  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [uploadedDocs, onUploadedDocs] = useState<JobDocument[]>([]);

  // const handleErrorSubmit = useCallback(async (error: string) => {}, []);

  useEffect(() => {
    if (!jobId) {
      return;
    }
    getJob({ jobId });
  }, [certidaoPermanenteDoc?.id, certidaoPermanenteDoc?.status, jobId, getJob]);

  useDidUpdateEffect(() => {
    if (errorSubmit) {
      const message = (errorSubmit as any)?.data?.message ? (errorSubmit as any)?.data?.message : '';
      setErrorMessage(t(message));
      setIsErrorModalOpen(true);
    }
  }, [errorSubmit]);

  const handleSubmit = useCallback(
    async (dto: JobSubmitParamsDto) => {
      console.debug('Handle submit');
      if (!job || !jobId) {
        console.error('Invalid application state! Not found job to submit.');
        return;
      }

      await submitJob({ jobId, dto });

      getJob({ jobId });
    },
    [job, submitJob, jobId, getJob],
  );

  const handleNameChange = useCallback(
    async (name: string) => {
      console.debug('Handle update');
      if (!job || !jobId) {
        console.error('Invalid application state! Not found job to update.');
        return;
      }
      try {
        await updateJob({ id: jobId, name }).unwrap();
      } catch (error) {
        showApiError(error);
      }
    },
    [job, jobId, updateJob, showApiError],
  );

  const handleJobModeChange = useCallback(
    async (mode: JobMode) => {
      if (!job || !jobId) {
        console.error('Invalid application state! Not found job to update.');
        return;
      }

      try {
        await updateJob({ id: jobId, mode }).unwrap();
      } catch (error) {
        showApiError(error);
      }
    },
    [job, jobId, updateJob, showApiError],
  );

  if (isLoading && !job) {
    return (
      <Flex align="center" justifyContent="center">
        <Spinner />
      </Flex>
    );
  }

  if (error || !job) {
    return <ErrorDisplay error={error} hasJobCheck job={job} />;
  }

  return (
    <JobDetailsWidget
      job={job}
      onSubmit={handleSubmit}
      onNameUpdate={handleNameChange}
      onJobModeChange={handleJobModeChange}
      isLoadingSubmit={isLoadingSubmit}
      isErrorModalOpen={isErrorModalOpen}
      onErrorModalClose={() => {
        console.debug('Close');
        setIsErrorModalOpen(false);
      }}
      errorMessage={errorMessage}
      uploadedDocs={uploadedDocs}
      onUploadedDocs={onUploadedDocs}
    />
  );
}
