import { useTranslation } from 'react-i18next';
import {
  Box,
  Checkbox,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { useCallback, useState } from 'react';
import {
  CertificateRenewalDto,
  RelatedParty,
  useLazyGetCertificateRenewalDataQuery,
  useRenewCertificateMutation,
} from 'api';
import { useRenewalRequestForm } from './useRenewalRequestForm';
import { CertificateRenewalSuccess } from './CertificateRenewalSuccess';
import { AppButton, AppButtonType, AppButtonColorScheme } from '../../libs/ui/atoms/src/lib/appButton';
import { useRelatedParties } from '../../views/admin/job/widgets/AddContractsWidget/useRelatedParties';
import { getAddressParts } from 'utils/get-address-parts';
import { useAppToast } from '../../libs/ui/hooks';
import {
  getParishCode,
  isNotProd,
  VC_DB_PREDIAL_DISTRICTS,
  VC_DB_PREDIAL_MUNICIPALITIES,
  VC_DB_PREDIAL_PARISHS,
} from '../../utils';
import { SelectInputInputField } from '../../libs/ui/atoms/src/lib/input-field/selectField';
import { FormInputField } from '../../libs/ui/atoms/src/lib/input-field/form-input-field';

export interface CertificateRenewalActionProps {
  jobId: string;
  problemId?: string;

  isPrimaryButton?: boolean;
  isSecondaryButton?: boolean;
}

export const CertificateRenewalAction = ({
  jobId,
  problemId,
  isPrimaryButton: isPrimaryMainButton,
  isSecondaryButton: isPrimaryButton,
}: CertificateRenewalActionProps) => {
  const { t } = useTranslation();
  const { showCatchedError, showApiError, showValidationError } = useAppToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isSuccessOpen, setIsSuccessOpen] = useState(false);
  const [isSimulateRequest, setIsSimulateRequest] = useState(false);

  const [getCertificateRenewalData, { isLoading: isLoadingData, data }] = useLazyGetCertificateRenewalDataQuery({});
  const [renewCertificate, { isLoading }] = useRenewCertificateMutation({});

  const { form, dispatch, validate } = useRenewalRequestForm();

  interface InitialOrgData {
    name: string;
    nif?: string;
    email?: string;
    address?: string;
    localidade?: string;
    postalCode?: string;
  }

  const [initialOrgData, setInitialOrgData] = useState<InitialOrgData>({ name: '' });
  const { sellers }: { sellers: RelatedParty[] } = useRelatedParties(jobId, true);

  const open = useCallback(async () => {
    try {
      const data = await getCertificateRenewalData({ jobId, problemId });
      if (data.isError || !data.data) {
        showApiError(data.error, t('certificateRenewal.unableToGetRenewalData'));
        return;
      }

      const orgData = {
        name: data.data.name,
        nif: data.data.nif,
        email: data.data.email,
        address: data.data.address,
        localidade: data.data.location,
        postalCode: data.data.postalCode,
      };

      setInitialOrgData(orgData);

      const parishId = data.data.identifier_parish_id ?? getParishCode(data.data.identifier_parish);

      dispatch({ type: 'setInitialState', data: { ...data.data, identifier_parish_id: parishId } });
      onOpen();
    } catch (error) {
      showCatchedError(error, t('certificateRenewal.unableToGetRenewalData'));
    }
  }, [getCertificateRenewalData, jobId, problemId, dispatch, onOpen, showApiError, t, showCatchedError]);

  const close = useCallback(() => {
    dispatch({ type: 'setInitialState', data: {} });
    onClose();
  }, [onClose, dispatch]);

  const onSubmit = useCallback(async () => {
    const validationMessages = validate();
    if (validationMessages.length > 0) {
      showValidationError(validationMessages.map(i => t(i)).join(', \n'));
      return;
    }

    try {
      const result = await renewCertificate({
        ...(form as CertificateRenewalDto),
        jobId,
        problemId: problemId,
        simulateRequest: isSimulateRequest,
      }).unwrap();
      if (!result.success) {
        return;
      }

      close();
      setIsSuccessOpen(true);
    } catch (error) {
      showApiError(error, t('certificateRenewal.unableToRenewCertificate'));
    }
  }, [
    validate,
    showValidationError,
    t,
    renewCertificate,
    form,
    jobId,
    problemId,
    isSimulateRequest,
    close,
    showApiError,
  ]);

  const onSuccessClose = useCallback(() => {
    setIsSuccessOpen(false);
  }, [setIsSuccessOpen]);

  const textColor = useColorModeValue('navy.750', 'white');

  const handleCopyFirstSellerDetails = () => {
    const firstSeller = sellers[0];

    dispatch({ type: 'setName', name: firstSeller.name });
    dispatch({ type: 'setNifNips', nif: firstSeller.nif ?? '' });
    dispatch({ type: 'setEmail', email: firstSeller.email ?? '' });

    if (firstSeller.relationType === 'PERSON') {
      const address = firstSeller.address ?? '';
      const { addressLine, postalCode, location } = getAddressParts(address);

      dispatch({ type: 'setAddress', address: addressLine ?? '' });
      dispatch({ type: 'setLocation', location: location ?? '' });
      dispatch({ type: 'setPostalCode', postalCode: postalCode ?? '' });
    }

    if (firstSeller.relationType === 'COMPANY') {
      dispatch({ type: 'setAddress', address: firstSeller.corpAddress ?? '' });
      dispatch({ type: 'setLocation', location: firstSeller.corpAddressParish ?? '' });
      dispatch({ type: 'setPostalCode', postalCode: firstSeller.corpAddressPostalCode ?? '' });
    }

    if (firstSeller.relationType === 'HEIR') {
      dispatch({ type: 'setAddress', address: '' });
      dispatch({ type: 'setLocation', location: '' });
      dispatch({ type: 'setPostalCode', postalCode: '' });
    }
  };

  const handleCopyOrganizationDetails = () => {
    if (initialOrgData) {
      dispatch({ type: 'setName', name: initialOrgData.name ?? '' });
      dispatch({ type: 'setNifNips', nif: initialOrgData.nif ?? '' });
      dispatch({ type: 'setEmail', email: initialOrgData.email ?? '' });
      dispatch({ type: 'setAddress', address: initialOrgData.address ?? '' });
      dispatch({ type: 'setLocation', location: initialOrgData.localidade ?? '' });
      dispatch({ type: 'setPostalCode', postalCode: initialOrgData.postalCode ?? '' });
    }
  };

  const getSellerInfoText = useCallback(
    (sellers: RelatedParty[]) => {
      if (sellers.length) {
        const firstSeller = sellers[0];
        const name = firstSeller.name.split(' ');
        if (firstSeller.name) {
          if (name.length > 1) {
            return `${t('certificateRenewal.copySellerInfo')} (${name[0]} ${name.slice(-1)}) `;
          } else {
            return `${t('certificateRenewal.copySellerInfo')} (${name})`;
          }
        }
      }
      return t('certificateRenewal.copySellerInfo');
    },
    [t],
  );

  const getOrganizationInfoText = useCallback(
    (initialOrgData: InitialOrgData) => {
      if (initialOrgData.name) {
        const name = initialOrgData.name.split(' ');
        if (name.length > 1) {
          return `${t('certificateRenewal.copyOrgDetails')} (${name[0]} ${name.slice(-1)})`;
        } else {
          return `${t('certificateRenewal.copyOrgDetails')} (${name})`;
        }
      }
      return t('certificateRenewal.copyOrgDetails');
    },
    [t],
  );

  return (
    <>
      {isPrimaryMainButton ? (
        <AppButton
          buttonType={AppButtonType.PRIMARY_MAIN}
          title={t('jobMode.renewCertificate.title')}
          onClick={open}
          isLoading={isLoadingData}
        />
      ) : isPrimaryButton ? (
        <AppButton
          buttonType={AppButtonType.PRIMARY}
          title={t('jobMode.renewCertificate.title')}
          onClick={open}
          isLoading={isLoadingData}
        />
      ) : (
        <AppButton
          buttonType={AppButtonType.PRIMARY}
          title={t('update')}
          isLoading={isLoadingData}
          onClick={open}
          colorSchemes={[AppButtonColorScheme.SECONDARY]}
        />
      )}

      <CertificateRenewalSuccess isOpen={isSuccessOpen} onClose={onSuccessClose} />

      <Modal size="xl" isOpen={isOpen} onClose={close} isCentered scrollBehavior="inside">
        <ModalOverlay />
        <ModalContent color={textColor} maxH={'calc(100% - 0rem)'} maxW={'900px'} p="24px" borderRadius="16px">
          <ModalHeader p="0" fontSize="24px">
            {t('certificateRenewal.form.title')}
          </ModalHeader>
          <ModalCloseButton right="24px" top="none" />
          <ModalBody p="24px 1px">
            <Flex direction="column" gap="32px">
              <Flex direction="column" gap="12px">
                <Flex direction="row" gap="12px" alignItems={'center'}>
                  <Text fontSize="16px" fontWeight="700" style={{ whiteSpace: 'nowrap' }}>
                    {t('certificateRenewal.form.detailsOfApplicant')}
                  </Text>

                  <Divider orientation="horizontal" pt={'2px'} opacity={1} />
                </Flex>

                <Grid columnGap={'24px'} rowGap={'16px'} gridTemplateColumns={{ sm: '1fr', md: '1fr 1fr' }}>
                  {sellers.length > 0 && (
                    <AppButton
                      mt={4}
                      width="100%"
                      onClick={handleCopyFirstSellerDetails}
                      buttonType={AppButtonType.XSMALL}
                      title={getSellerInfoText(sellers)}
                      colorSchemes={[AppButtonColorScheme.TRANSPARENT]}
                    />
                  )}
                  <AppButton
                    mt={4}
                    width="100%"
                    onClick={handleCopyOrganizationDetails}
                    buttonType={AppButtonType.XSMALL}
                    title={getOrganizationInfoText(initialOrgData)}
                    colorSchemes={[AppButtonColorScheme.TRANSPARENT]}
                  />
                  {sellers.length === 0 && <Box mt={4} width="100%" />}

                  <FormInputField
                    label={t('certificateRenewal.form.name')}
                    value={form.name}
                    onChange={value => dispatch({ type: 'setName', name: value })}
                  />
                  <FormInputField
                    label={t('certificateRenewal.form.email')}
                    value={form.email}
                    onChange={value => dispatch({ type: 'setEmail', email: value })}
                  />
                  <FormInputField
                    label={t('certificateRenewal.form.nifNips')}
                    value={form.nif}
                    onChange={value => dispatch({ type: 'setNifNips', nif: value })}
                  />
                  <FormInputField
                    label={t('certificateRenewal.form.address')}
                    value={form.address}
                    onChange={value => dispatch({ type: 'setAddress', address: value })}
                  />
                  <FormInputField
                    label={t('certificateRenewal.form.location')}
                    value={form.location}
                    onChange={value => dispatch({ type: 'setLocation', location: value })}
                  />
                  <FormInputField
                    label={t('certificateRenewal.form.postalCode')}
                    value={form.postalCode}
                    onChange={value => dispatch({ type: 'setPostalCode', postalCode: value })}
                  />
                </Grid>
              </Flex>

              <Flex direction="column" gap="12px">
                <Flex direction="row" gap="12px" alignItems={'center'}>
                  <Text fontSize="16px" fontWeight="700" style={{ whiteSpace: 'nowrap' }}>
                    {t('certificateRenewal.form.detailsOfCertidaoPermanente')}
                  </Text>

                  <Divider orientation="horizontal" pt={'2px'} opacity={1} />
                </Flex>

                <Grid columnGap={'24px'} rowGap={'16px'} gridTemplateColumns={{ sm: '1fr', md: '1fr 1fr' }}>
                  {!data?.identifier_parish_id ? (
                    <>
                      <FormControl>
                        <FormLabel>{t('relatedParties.corpAddressDistrict')}</FormLabel>
                        <SelectInputInputField
                          placeholder={t('relatedParties.corpAddressDistrict')}
                          items={VC_DB_PREDIAL_DISTRICTS.map(i => ({ value: i[0], label: i[1] }))}
                          value={form.identifier_parish_id?.slice(0, 2)}
                          onChange={value => dispatch({ type: 'setIdentifierParishId', value })}
                        />
                      </FormControl>
                      <FormControl>
                        <FormLabel>{t('relatedParties.corpAddressMunicipality')}</FormLabel>
                        <SelectInputInputField
                          placeholder={t('relatedParties.corpAddressMunicipality')}
                          items={VC_DB_PREDIAL_MUNICIPALITIES.filter(
                            i => form.identifier_parish_id && i[0].startsWith(form.identifier_parish_id.slice(0, 2)),
                          ).map(i => ({ value: i[0], label: i[1] }))}
                          value={
                            (form.identifier_parish_id ?? '')?.length >= 4
                              ? form.identifier_parish_id?.slice(0, 4)
                              : undefined
                          }
                          onChange={value => dispatch({ type: 'setIdentifierParishId', value })}
                        />
                      </FormControl>
                      <FormControl>
                        <FormLabel>{t('relatedParties.corpAddressParish')}</FormLabel>
                        <SelectInputInputField
                          isReadonly
                          placeholder={t('relatedParties.corpAddressParish')}
                          items={VC_DB_PREDIAL_PARISHS.filter(
                            i => form.identifier_parish_id && i[0].startsWith(form.identifier_parish_id.slice(0, 4)),
                          ).map(i => ({ value: i[0], label: i[1] }))}
                          value={(form.identifier_parish_id ?? '')?.length >= 6 ? form.identifier_parish_id : undefined}
                          onChange={value => dispatch({ type: 'setIdentifierParishId', value })}
                        />
                      </FormControl>
                    </>
                  ) : (
                    <FormInputField
                      isReadonly
                      label={t('certificateRenewal.form.identifierParish')}
                      value={data?.identifier_parish}
                    />
                  )}
                  <FormInputField
                    isReadonly
                    label={t('certificateRenewal.form.identifierConservatoria')}
                    value={data?.identifier_conservatória}
                  />
                  <FormInputField
                    isReadonly
                    label={t('certificateRenewal.form.certidaoIdentifier')}
                    value={data?.certidaoIdentifier}
                  />

                  {isNotProd() && (
                    <Checkbox isChecked={isSimulateRequest} onChange={e => setIsSimulateRequest(!isSimulateRequest)}>
                      Simulate request
                    </Checkbox>
                  )}
                </Grid>
              </Flex>
            </Flex>
          </ModalBody>

          <ModalFooter justifyContent="center" gap="24px" p="0">
            <AppButton
              buttonType={AppButtonType.PRIMARY_MAIN}
              isLoading={isLoading}
              onClick={onSubmit}
              title={t('certificateRenewal.submit')}
            />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
