import { Flex } from '@chakra-ui/react';
import { AppButton, AppButtonType, AppButtonColorScheme, SelectionMenuOption } from 'libs/ui/atoms/src';
import { useTranslation } from 'react-i18next';

const LANGUAGES_OPTIONS: SelectionMenuOption[] = [
  {
    title: 'EN',
    value: 'en',
  },
  {
    title: 'PT',
    value: 'pt',
  },
];

export const LanguageSelector = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
  };

  return (
    <Flex align="center" justify="space-between" gap="16px">
      {LANGUAGES_OPTIONS.map(option => (
        <AppButton
          key={'langSelector.' + option.value}
          buttonType={AppButtonType.SECONDARY}
          colorSchemes={
            option.value === i18n.resolvedLanguage
              ? [AppButtonColorScheme.DISABLED]
              : [AppButtonColorScheme.TRANSPARENT]
          }
          title={option.title}
          onClick={() => changeLanguage(option.value)}
          isWidePadding={true}
        />
      ))}
    </Flex>
  );
};
