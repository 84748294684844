import { FormInputField } from '../../atoms/src/lib/input-field/form-input-field';
import { IBANInputField } from '../../atoms/src/lib/input-field/iban-input-field';
import { PriceInputField } from '../../atoms/src/lib/input-field/price-input-field';

import './table-inline-edit-cell.css';

// TODO: fix all any types here. IT'S TEMPORARY..
export enum TableInlineEditColumnType {
  STRING = 'STRING',
  NUMBER = 'NUMBER',
  PRICE = 'PRICE',
  IBAN = 'IBAN',
}

export interface TableInlineEditCellProps<TValue = string | number> {
  key?: string;
  type: TableInlineEditColumnType;
  value?: TValue | null;
  isInvalid?: boolean;

  onChange?: (value: TValue) => void;
  onBlur?: (value: TValue) => void;
  onBlurOrValid?: (value: TValue) => void;
}

export const TableInlineEditCell = <TValue extends string | number>(props: TableInlineEditCellProps<TValue>) => {
  const { key, type, value, isInvalid, onChange, onBlur, onBlurOrValid } = props;

  if (type === TableInlineEditColumnType.PRICE) {
    return (
      <PriceInputField
        key={key}
        value={value as number}
        isInvalid={isInvalid}
        onChange={onChange as any}
        onBlur={onBlur as any}
        thousandSeparator={' '}
        decimalSeparator={','}
        paddingRight="0"
      />
    );
  }

  if (type === TableInlineEditColumnType.STRING) {
    return (
      <FormInputField key={key} value={value} isInvalid={isInvalid} onChange={onChange as any} onBlur={onBlur as any} />
    );
  }

  if (type === TableInlineEditColumnType.NUMBER) {
    return (
      <FormInputField
        key={key}
        type={'number'}
        value={value}
        isInvalid={isInvalid}
        onChange={onChange as any}
        onBlur={onBlur as any}
      />
    );
  }

  if (type === TableInlineEditColumnType.IBAN) {
    return (
      <IBANInputField
        key={key}
        hideError
        value={value as string}
        isInvalid={isInvalid}
        onChange={onChange as any}
        onBlur={onBlur as any}
        onBlurOrValid={onBlurOrValid as any}
        paddingRight="8px"
      />
    );
  }

  return <></>;
};
