import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User as UserAuth } from '@auth0/auth0-react';
import { Organization, User, UserPrivateEndpoints } from '../api';
import { createContextToken } from '../auth0/auth0.initializer';

export interface AuthState {
  accessToken: string | null;
  contextToken: string | null;
  organizationId: string | null;
  user: User | null;
  userAuth: UserAuth | null;
  organizations: Organization[] | null;
}

const authInitialState: AuthState = {
  accessToken: null,
  contextToken: null,
  organizationId: localStorage.getItem('organizationId') || null,
  user: null,
  userAuth: null,
  organizations: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState: authInitialState,
  reducers: {
    resetAuth: state => ({ ...state, ...authInitialState }),
    setUserAuthAndAccessToken: (
      state,
      { payload }: PayloadAction<{ userAuth: UserAuth; accessToken: string; contextToken: string }>,
    ) => ({
      ...state,
      contextToken: payload.contextToken,
      accessToken: payload.accessToken,
      userAuth: payload.userAuth,
    }),
    setOrganizationId: (state, { payload }: PayloadAction<{ organizationId: string | null }>) => {
      localStorage.setItem('organizationId', payload.organizationId || '');
      return {
        ...state,
        organizationId: payload.organizationId,
        contextToken: createContextToken({
          userId: state.user?.id || '',
          organizationId: payload.organizationId,
        }),
      };
    },
  },
  extraReducers: builder =>
    builder.addMatcher(UserPrivateEndpoints.me.matchFulfilled, (state, { payload: user }) => {
      let authState = { ...state };
      if (!state.organizationId && !user.isPersonalEnabled) {
        authState = authSlice.reducer(
          state,
          setOrganizationId({ organizationId: user?.organizations?.[0]?.id ?? null }),
        );
      }
      if (state.organizationId && !user?.organizations?.find(org => org.id === state.organizationId)) {
        authState = authSlice.reducer(
          state,
          setOrganizationId({ organizationId: user?.organizations?.[0]?.id ?? null }),
        );
      }
      authState = {
        ...authState,
        contextToken: createContextToken({
          userId: user.id,
          organizationId: state.organizationId,
        }),
        user: user,
        organizations: user.organizations ?? [],
      };
      return authState;
    }),
});

export const { reducer: authReducer, reducerPath: authReducerPath } = authSlice;
export const { setUserAuthAndAccessToken, setOrganizationId, resetAuth } = authSlice.actions;
