import { Flex, Spinner } from '@chakra-ui/react';
import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useTypedSelector } from 'store';
import { selectJob } from 'store/job';
import {
  useCreateJobMutation,
  useSubmitJobMutation,
  useUpdateJobMutation,
  JobDocument,
  JobMode,
  JobSubmitParamsDto,
} from '../../../api';
import { JobDetailsWidget } from './widgets/JobDetailsWidget/JobDetailsWidget';
import { jobWizardStepChanged } from 'store/jobWizard';
import { ErrorDisplay } from 'libs/ui/atoms/src/lib/errorDisplay/ErrorDisplay';
import { useAppToast } from '../../../libs/ui/hooks';

export function JobAddPage() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { showApiError } = useAppToast();

  const [createJob, { isLoading, error, data }] = useCreateJobMutation();

  React.useEffect(() => {
    dispatch(jobWizardStepChanged({ wizardKey: 'jobWizard', step: 1 }));
    createJob({});
  }, []);

  const job = useTypedSelector(state => (data?.id ? selectJob(state, data.id) : null));

  const [submitJob, { isLoading: isLoadingSubmit, error: errorSubmit }] = useSubmitJobMutation();
  const [updateJob] = useUpdateJobMutation();

  const [uploadedDocs, onUploadedDocs] = useState<JobDocument[]>([]);

  const navigateToJobDetails = () => {
    if (!job) {
      console.error('Invalid application state! Not found job to navigate.');
      return;
    }
    navigate(`/jobs/${job.id}`);
  };

  const handleSubmit = useCallback(
    async (dto: JobSubmitParamsDto) => {
      console.debug('Handle submit');
      if (!job) {
        console.error('Invalid application state! Not found job to submit.');
        return;
      }

      await submitJob({
        jobId: job.id,
        dto,
      });

      navigateToJobDetails();
    },
    [job, submitJob],
  );

  const handleNameChange = useCallback(
    async (name: string) => {
      console.debug('Handle update');
      if (!job) {
        console.error('Invalid application state! Not found job to update.');
        return;
      }
      try {
        await updateJob({ id: job.id, name }).unwrap();
      } catch (error) {
        showApiError(error);
      }
    },
    [job, updateJob, showApiError],
  );

  const handleJobModeChange = useCallback(
    async (mode: JobMode) => {
      if (!job) {
        console.error('Invalid application state! Not found job to update.');
        return;
      }

      try {
        await updateJob({ id: job.id, mode }).unwrap();
      } catch (error) {
        showApiError(error);
      }
    },
    [job, updateJob, showApiError],
  );

  if (isLoading) {
    return (
      <Flex align="center" justifyContent="center">
        <Spinner />
      </Flex>
    );
  }

  if (error || !job) {
    return <ErrorDisplay error={error} hasJobCheck job={job} />;
  }

  return (
    <JobDetailsWidget
      job={job}
      onSubmit={handleSubmit}
      onJobModeChange={handleJobModeChange}
      onNameUpdate={handleNameChange}
      isLoadingSubmit={isLoadingSubmit}
      hideExtraData
      uploadedDocs={uploadedDocs}
      onUploadedDocs={onUploadedDocs}
    />
  );
}
