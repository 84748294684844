import { api } from './api';
import { defaultSerializeQueryArgs } from '@reduxjs/toolkit/query';
import { itemUpsertManyReducer } from '../store/list';
import { JobDocument } from './job-documents.private';
import { Template } from './template';
import { Signing } from './signing';
import { User } from './user.private';
import { VCExtraDataType } from './extra-data/vc-extra-data.data-type';
import { VCExtraData } from './extra-data/extra-data';
import { ContractFieldsUpdateDto } from './extra-data/contract-fields';

export interface JobResponse {
  data?: Job[] | null;
  totalCount: number;
  pageCount: number;
}

export interface JobStatusMetadata {
  status: string;
  traceId: string;
}

export interface Job {
  id: string;
  publicId?: string | null;
  name: string;
  description: string;
  isPublished: boolean;
  organizationId: string;
  certidaoIdentifier?: string;
  statusMetadata?: JobStatusMetadata;
  status: JobStatus;
  mode: JobMode;

  ownerId: string;
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  updatedBy: string;
  publishedAt?: string | null;
  publishedBy?: string | null;

  problems: JobProblem[];
  templates?: Template[];
  contractTexts: JobMetadataContractText[];
  signing?: Signing;
  ownerUser: User;

  jobMetadata: JobMetadata;
  extraDataContractFields?: VCExtraData<VCExtraDataType.CONTRACTS_EXTRA_FIELDS>;
}

export enum JobStatus {
  'Draft' = 'Draft',
  'Submitted' = 'Submitted',
  'Error occurred' = 'Error occurred',
  'Problems detected' = 'Problems detected',
  'Job is completed' = 'Job is completed',
  'job is archived' = 'job is archived',
}

export enum JobMode {
  CREATE_REPORT = 'CREATE_REPORT',
  CREATE_CONTRACT = 'CREATE_CONTRACT',
  RENEW_CERTIFICATE = 'RENEW_CERTIFICATE',
  DO_DDP = 'DO_DDP',
  DO_IMPIC = 'DO_IMPIC',
}

export interface JobMetadataContractText {
  labelEn: string;
  labelPt: string;
  contractTextEn: string;
  contractTextPt: string;
}

export enum JobMetadataId {
  /**
   * Additional values for rights of refusal.
   */
  R01X01 = 'R01X01',
  /**
   * Contract formatted texts for copy tools.
   */
  R01X02 = 'R01X02',
  /**
   * Property Info.
   */
  R01X03 = 'R01X03',
  /**
   * Matrix Articles.
   */
  R03X01 = 'R03X01',
  /**
   * Bank transfers and cheques.
   */
  R01X04 = 'R01X04',
}

export type JobMetadataItem = JobMetadataR03X01 | JobMetadataR01X03 | JobMetadataR01X04;
export type JobMetadata = {
  metadata: JobMetadataItem[];
};

export interface JobMetadataBase {
  id: JobMetadataId;
}

export interface JobMetadataR03X01Item {
  id: string;
  type: string;
}

export interface JobMetadataR03X01 extends JobMetadataBase {
  id: JobMetadataId.R03X01;
  metadata: JobMetadataR03X01Item[];
}

export interface JobMetadataR01X03Item {
  anoConstrucao: string;
  anoConstrucaoNaoSei: string;
  areaTotal: string;
  areaTotalDD: string;
  areaTotalNaoSei: string;
  certificacaoEnergetica: string;
  codPostal1: string;
  codPostal2: string;
  distConcFreg: string;
  financas: string;
  fracao: string;
  habitacaoPropria: string;
  localidade: string;
  matricial: string;
  morada: string;
  numeroPisos: string;
  numeroPisosNaoSei: string;
  predial: string;
  registoPredial: string;
  tipoDePredio: string;
  tipoEdificacao: string;
  tipoImovelResidencial: string;
  tipoTerreno: string;
  tipologia: string;
}

export interface JobMetadataR01X03 extends JobMetadataBase {
  id: JobMetadataId.R01X03;
  metadata: JobMetadataR01X03Item;
}

export interface JobMetadataR01X04BankTransferItem {
  'transfer:iban:remitter'?: string;
  'transfer:iban:beneficiary'?: string;
  amount?: number;
}

export interface JobMetadataR01X04ChequeItem {
  'transfer:cheque:number'?: number;
  'transfer:cheque:drawee'?: string;
  amount?: number;
}

export interface JobMetadataR01X04 extends JobMetadataBase {
  id: JobMetadataId.R01X04;
  metadata: {
    bank_payments: JobMetadataR01X04BankTransferItem[];
    cheque_payments: JobMetadataR01X04ChequeItem[];
  };
}

export interface JobWithDocumentsAndResult {
  id: string;
  name: string;
  publicId?: string | null;
  description: string;
  status: JobStatus;
  mode: JobMode;
  organizationId: string;
  statusMetadata?: JobStatusMetadata;
  isPublished: boolean;
  filesArchiveUrl: string;

  ownerId: string;
  createdBy: string;
  createdAt: string;
  updatedBy: string;
  updatedAt: string;
  publishedAt?: string | null;
  publishedBy?: string | null;

  documents?: JobDocument[] | null;
  jobResult: JobResult;
  problems: JobProblem[];
  contractTexts: JobMetadataContractText[];
  signing?: Signing;
  ownerUser: User;

  jobMetadata: JobMetadata;
}

export interface JobProblem {
  id: string;
  jobId: string;
  errorId: string;
  textEn: string;
  textPt: string;
  createdAt: string;
  metadata?: {
    identifier_parish?: string;
    document_identifier?: string;
    identifier_conservatória?: string;
    identifier_parish_id?: string;
  };
}

export interface JobResult {
  id: string;
  name: string;
  url: string;
  status: string;
  jobResultFiles?: JobResultDocument[] | null;
  createdAt: string;
}

export interface JobResultDocument {
  id: string;
  name: string;
  url: string;
  createdAt: string;
}

export interface JobCreateDto {
  name?: string | null;
  description?: string | null;
}

export interface JobUpdateDto {
  id: string;
  name?: string | null;
  description?: string | null;
  templateIds?: string[];
  mode?: JobMode;
}

export interface JobSubmitParamsDto {
  isGenerateReportEN: boolean;
  isGenerateReportPT: boolean;
  isPrivate: boolean;
}

export interface JobGetListDto {
  skip?: number;
  take?: number;
  orderBy?: string;
  orderDirection?: string;
  search?: string;
  status?: JobStatus;
  lastUpdatedAt?: Date;
}

export interface JobTransferOwnershipDto {
  jobId: string;
  newOwnerId: string;
}

export enum ImpicQualidadeDeclaranteNT {
  MEDIACAO_IMOBILIARIA = 'MI',
  COMPRA_VENDA_COMPRA_PARA_REVENDA_PERMUTA_IMOVEIS = 'CV',
  // PROMOCAO_IMOBILIARIA = 'PI',
  // ARRENDAMENTO = 'A',
  // OUTRA_ATIVIDADE_IMOBILIARIA = 'OAI',
}

export enum ImpicContractPeriod {
  SEMESTER_1 = '1',
  SEMESTER_2 = '2',
  TRIMESTER_1 = '3',
  TRIMESTER_2 = '4',
  TRIMESTER_3 = '5',
  TRIMESTER_4 = '6',
}

export enum ImpicTransactionType {
  COMPRA = 'C',
  VENDA = 'V',
  PERMUTA = 'P',
  ARRENDAMENTO = 'A',
}

export interface ImpicBankTransferItem {
  remitterIban?: string;
  beneficiaryIban?: string;
  amountPrice?: number;
}

export interface ImpicChequeItem {
  number?: number;
  drawee?: string;
  amountPrice?: number;
}

export interface ImpicDeclarationData {
  qualidadeDeclarante: ImpicQualidadeDeclaranteNT;
  contractYear: string;
  contractPeriod: ImpicContractPeriod;
  transactionType: ImpicTransactionType;
  transactionDate: string;

  propertyValue: string;
  propertyNumber: string;
  quotaParteImoveis: string;
  valorRendaIncluiDespEnc: string;

  codPostal1?: string;
  codPostal2?: string;
  localidade?: string;

  bankTransfers: ImpicBankTransferItem[];
  cheques: ImpicChequeItem[];
  bankTransfersAmountPriceSum?: string;
  chequesAmountPriceSum?: string;
}

const jobPrivateApi = api.injectEndpoints({
  endpoints: build => ({
    getJobs: build.query<JobResponse, JobGetListDto>({
      query: ({ skip, take, orderBy, orderDirection, search, status, lastUpdatedAt }) => ({
        url: '/job',
        params: { skip, take, orderBy, orderDirection, search, status, lastUpdatedAt },
      }),
      serializeQueryArgs: ({ queryArgs: { skip, take, ...queryArgs }, endpointName, endpointDefinition }) =>
        defaultSerializeQueryArgs({
          queryArgs,
          endpointName,
          endpointDefinition,
        }),
      merge: (currentCacheData, responseData) => {
        if (currentCacheData.data && responseData.data) {
          return {
            ...currentCacheData,
            ...responseData,
            data: itemUpsertManyReducer(currentCacheData.data, {
              items: responseData.data,
              addAtEnd: true,
              replaceOldWithNew: true,
            }),
          };
        }
        return responseData;
      },
    }),
    getJobsList: build.query<JobResponse, JobGetListDto>({
      query: ({ skip, take, orderBy, orderDirection, search, status, lastUpdatedAt }) => ({
        url: '/job',
        params: { skip, take, orderBy, orderDirection, search, status, lastUpdatedAt },
      }),
      serializeQueryArgs: ({ queryArgs: { skip, take, ...queryArgs }, endpointName, endpointDefinition }) =>
        defaultSerializeQueryArgs({
          queryArgs,
          endpointName,
          endpointDefinition,
        }),
    }),
    getJob: build.query<JobWithDocumentsAndResult, { jobId: string }>({
      query: ({ jobId }) => ({ url: `/job/${jobId}` }),
    }),
    newJob: build.query<JobWithDocumentsAndResult, JobCreateDto>({
      query: dto => ({
        url: '/job',
        body: dto,
        method: 'POST',
      }),
    }),
    createJob: build.mutation<JobWithDocumentsAndResult, JobCreateDto>({
      query: dto => ({
        url: '/job',
        body: dto,
        method: 'POST',
      }),
    }),
    updateJob: build.mutation<JobWithDocumentsAndResult, JobUpdateDto>({
      query: dto => ({
        url: `/job`,
        body: dto,
        method: 'PUT',
      }),
    }),
    submitJob: build.mutation<JobWithDocumentsAndResult, { jobId: string; dto: JobSubmitParamsDto }>({
      query: ({ jobId, dto }) => ({
        url: `/job/${jobId}/submit`,
        method: 'POST',
        body: dto,
      }),
    }),
    submitContractsJob: build.mutation<JobWithDocumentsAndResult, { jobId: string }>({
      query: ({ jobId }) => ({
        url: `/job/${jobId}/submitContracts`,
        method: 'POST',
      }),
    }),
    deleteJob: build.mutation<JobWithDocumentsAndResult, { jobId: string }>({
      query: ({ jobId }) => ({
        url: `/job/${jobId}`,
        method: 'DELETE',
      }),
    }),
    archiveJob: build.mutation<JobWithDocumentsAndResult, { jobId: string }>({
      query: ({ jobId }) => ({
        url: `/job/${jobId}/archive`,
        method: 'POST',
      }),
    }),
    unarchiveJob: build.mutation<JobWithDocumentsAndResult, { jobId: string }>({
      query: ({ jobId }) => ({
        url: `/job/${jobId}/unarchive`,
        method: 'POST',
      }),
    }),
    transferJobOwnership: build.mutation<JobWithDocumentsAndResult, JobTransferOwnershipDto>({
      query: dto => ({
        url: `/job/transferOwnership`,
        body: dto,
        method: 'POST',
      }),
    }),
    updateContractFields: build.mutation<VCExtraData<VCExtraDataType.CONTRACTS_EXTRA_FIELDS>, ContractFieldsUpdateDto>({
      query: ({ jobId, data }) => ({
        url: `/job/${jobId}/updateContractFields`,
        method: 'POST',
        body: data,
      }),
    }),
    buildImpicXml: build.mutation<string, { jobId: string; dto: ImpicDeclarationData }>({
      query: ({ jobId, dto }) => ({
        url: `/job/${jobId}/buildImpicXml`,
        method: 'POST',
        body: dto,
        responseHandler: async response => {
          if (!response.ok) {
            return await response.json();
          }
          return await response.text();
        },
      }),
    }),
  }),
});

export const {
  useGetJobsQuery: useGetJobsPrivateQuery,
  useLazyGetJobsQuery: useLazyGetJobsPrivateQuery,
  useGetJobQuery: useGetJobPrivateQuery,
  useLazyGetJobQuery: useLazyGetJobPrivateQuery,
  useGetJobsListQuery: useGetJobsListPrivateQuery,
  useLazyGetJobsListQuery: useLazyGetJobsListPrivateQuery,
  useNewJobQuery,
  useLazyNewJobQuery,
  useCreateJobMutation,
  useUpdateJobMutation,
  useDeleteJobMutation,
  useSubmitJobMutation,
  useSubmitContractsJobMutation,
  useArchiveJobMutation,
  useUnarchiveJobMutation,
  useTransferJobOwnershipMutation,
  useUpdateContractFieldsMutation,
  useBuildImpicXmlMutation,
  endpoints: JobPrivateEndpoints,
  util: JobPrivateApiUtil,
} = jobPrivateApi;
