import { CertificateRenewalDto } from 'api';
import { useCallback, useReducer } from 'react';
import { isEmailValid } from 'utils/validators';

type SetInitialStateAction = { type: 'setInitialState'; data: CertificateRenewalFormState };
type SetNameAction = { type: 'setName'; name: string };
type SetEmailAction = { type: 'setEmail'; email: string };
type SetNifNipsAction = { type: 'setNifNips'; nif: string };
type SetLocationAction = { type: 'setLocation'; location: string };
type SetAddressAction = { type: 'setAddress'; address: string };
type SetPostalCodeAction = { type: 'setPostalCode'; postalCode: string };
type SetIdentifierParishId = { type: 'setIdentifierParishId'; value: string };

type CertificateRenewalFormAction =
  | SetInitialStateAction
  | SetNameAction
  | SetEmailAction
  | SetNifNipsAction
  | SetLocationAction
  | SetAddressAction
  | SetPostalCodeAction
  | SetIdentifierParishId;

export type CertificateRenewalFormState = Omit<Partial<CertificateRenewalDto>, 'jobId'>;

export function useRenewalRequestForm() {
  const [form, dispatch] = useReducer((state: CertificateRenewalFormState, action: CertificateRenewalFormAction) => {
    switch (action.type) {
      case 'setInitialState':
        return { ...state, ...action.data };

      case 'setName': {
        return { ...state, name: action.name };
      }
      case 'setEmail': {
        return { ...state, email: action.email };
      }
      case 'setNifNips': {
        return { ...state, nif: action.nif };
      }
      case 'setLocation': {
        return { ...state, location: action.location };
      }
      case 'setAddress': {
        return { ...state, address: action.address };
      }
      case 'setPostalCode': {
        return { ...state, postalCode: action.postalCode };
      }
      case 'setIdentifierParishId': {
        return { ...state, identifier_parish_id: action.value };
      }
      default:
        return state;
    }
  }, {});

  const validate = useCallback<() => string[]>(() => {
    const errorMsgs = [];
    if (!form.name) {
      errorMsgs.push('nameIsRequired');
    }
    if (!form.email || !isEmailValid(form.email)) {
      errorMsgs.push('invalidEmail');
    }
    if (!form.nif) {
      errorMsgs.push('nifNipcIsRequired');
    }
    if (!form.location) {
      errorMsgs.push('locationIsRequired');
    }
    if (!form.address) {
      errorMsgs.push('addressIsRequired');
    }
    if (!form.postalCode) {
      errorMsgs.push('postalCodeIsRequired');
    }
    if (!form.identifier_parish_id || form.identifier_parish_id?.length !== 6) {
      errorMsgs.push('identifierParishIdIsNotValid');
    }

    return errorMsgs;
  }, [form]);

  return { form, dispatch, validate };
}
