import { Flex, StyleProps } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { JobMode } from 'api';
import { VCSelectionCard } from '../../../../libs/ui/atoms/src/lib/selection-card';

export interface JobModeSelectorProps extends StyleProps {
  jobMode: JobMode;
  isReadOnly?: boolean;
  isJobModeInColumn?: boolean;

  onChange: (jobMode: JobMode) => void;
}

const jobModes: { value: JobMode; title: string; subtitle: string }[] = [
  {
    value: JobMode.CREATE_REPORT,
    title: 'jobMode.createReport.title',
    subtitle: 'jobMode.createReport.subtitle',
  },
  {
    value: JobMode.CREATE_CONTRACT,
    title: 'jobMode.createContract.title',
    subtitle: 'jobMode.createContract.subtitle',
  },
  {
    value: JobMode.RENEW_CERTIFICATE,
    title: 'jobMode.renewCertificate.title',
    subtitle: 'jobMode.renewCertificate.subtitle',
  },
  {
    value: JobMode.DO_DDP,
    title: 'jobMode.doDdP.title',
    subtitle: 'jobMode.doDdP.subtitle',
  },
  {
    value: JobMode.DO_IMPIC,
    title: 'jobMode.doIMPIC.title',
    subtitle: 'jobMode.doIMPIC.subtitle',
  },
];

export function JobModeSelector({
  jobMode,
  isReadOnly,
  isJobModeInColumn,
  onChange,
  ...containerProps
}: JobModeSelectorProps) {
  const { t } = useTranslation();

  const handleCardClick = (value: JobMode) => {
    if (isReadOnly) return;
    if (value === jobMode) return;

    onChange(value);
  };

  return (
    <Flex
      flexDir={isJobModeInColumn ? 'column' : 'row'}
      align={'flex-start'}
      flexWrap={'wrap'}
      gap={'20px'}
      {...containerProps}
    >
      {jobModes.map(({ value, title, subtitle }, idx) => (
        <VCSelectionCard<JobMode>
          key={idx}
          value={value}
          isChecked={jobMode === value}
          title={t(title)}
          subtitle={t(subtitle)}
          onClick={handleCardClick}
        />
      ))}
    </Flex>
  );
}
