import { AppButton, AppButtonType, AppButtonColorScheme } from 'libs/ui/atoms/src';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const NavbarProfileButton = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <AppButton
      buttonType={AppButtonType.SECONDARY}
      colorSchemes={[AppButtonColorScheme.DISABLED]}
      title={t('myProfile')}
      onClick={() => navigate('/profile')}
      isWidePadding={true}
      width={'100%'}
    />
  );
};
